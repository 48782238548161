* {
  margin: 0;
  padding: 0;
  /* overflow-x: hidden; */
}

body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

:root {
  --btn1: #275345;
  --back: rgb(230, 230, 230);
  --mainColor: #f7d909;
  --btnColor: #a9f0cc;
  --hoverColor: #dfb75ae7;
  --green: #1e5d48bc;
}

.heading_of_banner > h3 {
  color: var(--txt);
}

.heading_of_banner > h2 {
  color: var(--txt);
  font-weight: bolder;
  font-size: calc(2.8 * var(--default));
}

.heading_of_banner {
  top: 0;
  left: 25%;
  margin-top: calc(5 * var(--default));
}

.solar-left-banner > h1 {
  font-size: 5.5rem;
}

.dp {
  padding: 0 40px;
}

/*pre defined classes*/
/* colors */
.backClr {
  color: var(--green);
}

.btnColor {
  background-color: var(--btnColor);
  transition: all linear 0.4s;
}

.btn:hover {
  background-color: var(--hoverColor);
}

/* font size */
.f1 {
  font-size: 1.6vw;
}

.f2 {
  font-size: 1.2vw;
}
.fontText-12 {
  font-size: 12px;
}
.fontText-14 {
  font-size: 14px;
}
.font-18 {
  font-size: 18px;
}
/* main heading */
.main-heading {
  color: #1e5d48bc;
  text-transform: uppercase;
  font-weight: 600;
  font-size: calc(1.1 * var(--default));
}

.head-line {
  line-height: 1.1;
}

/*pre defined classes end*/

.mySwiper1 .card {
  min-height: 600px;
}

section {
  overflow-x: hidden;
  overflow-y: hidden;
  margin-bottom: 20px;
}

.about-page {
  background: linear-gradient(290deg, rgba(0, 0, 0, 0.819), #439a7dbc);
}

.second-banner {
  background: rgb(35, 33, 33);
  /* background: linear-gradient(
      0deg,
      rgba(35, 33, 33, 0.4) 0%,
      rgba(47, 121, 9, 0.6) 0%,
      rgba(1, 1, 1, 0.4) 58%
    ),
    url("../res/assets/solarHeaderBg.jpg") no-repeat; */
  background-size: cover;
}
.sec-banner-card {
  height: 302px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.large-card {
  background: #6ba692;
  color: white;
}

/* banner section */

.banner-start {
  background: url("../res/assets/aboutBanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0;
}

.solar-power-heading {
  font-size: 4rem;
  font-weight: bold;
}

/* section who we are */
.who {
  margin-top: 20px;
}
.energy-cont {
  width: 43.4vw;
  height: 30vw;
  position: relative;
}

.img-cont-who {
  background-image: url(../res/assets/solarEnergy.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 90%;
  height: 90%;
}

.energy-text-cont {
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50%;
  width: 40%;
}

.energy-text-cont p {
  margin: 0;
}

/* our-solutions */
/* our-solutions */

.solutions-img-cont {
  color: white;
  /* background: linear-gradient(290deg, rgba(0, 0, 0, 0.819), #439a7dbc),
    url("../res/assets/solarPlantBg.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 510px; */
}
.Solar-img-cont {
  color: rgb(0, 0, 0);
}
.second-banner-overlap {
  margin-top: -17rem;
}
.overlap-cont {
  margin-top: -24rem;
}
.card > img {
  height: 14rem;
}
.hide-cont ul {
  padding-left: 12px;
}
.hide-cont > ul p {
  font-size: 14px;
}
.hide-cont > ul li {
  padding-left: 10px;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  list-style: disc;
}

/* offer */
.we-offer {
  margin-top: 30px;
}
.we-offer .card {
  height: 321px;
}

.icon-cont-card {
  background-color: var(--green);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  margin-bottom: 11px;
}
.icon-cont-card-Top {
  background-color: var(--green);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
}
.icon-cont-card-Top > img {
  height: 2.5rem;
  width: 2.5rem;
}
.icon-cont-card > img {
  height: 2.5rem;
  width: 2.5rem;
}
.text-of-card {
  font-size: 14px;
}
/* lets shine */
.shine {
  padding: 60px 0;
  /* background: linear-gradient(
      290deg,
      rgba(0, 0, 0, 0.6),
      rgba(67, 154, 125, 0.4)
    ),
    url("../images/shine-banner.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* better future */

.better {
  height: 460px;
  margin-bottom: 30px;
}
.better-img-cont {
  position: relative;
}

.better-img {
  /* border: 2px solid red; */
  background: url("../res/assets/solarPlantBg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 30rem;
}

.better-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  position: absolute;
  top: 41%;
  right: -50px;
}

.better-btn i {
  color: var(--mainColor);
}

button {
  margin: 0 !important;
}

/* .better-text{
   
} */

/* green-energy*/
.green-energy {
  padding: 2vw 0;
  background: linear-gradient(290deg, rgba(0, 0, 0, 0.819), #439a7dbc),
    url("../res/assets/solar.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.green-energy label {
  color: white;
}
/* awareness */
.awareness {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background: linear-gradient(290deg, rgba(0, 0, 0, 0.819), #439a7dbc),
    url("../res/assets/solar.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* responsivness start */

/* responsivness start */
@media screen and (max-width: 991px) {
  .img-cont-who {
    width: 100%;
    height: 400px;
  }

  .energy-cont {
    margin: 60px 0;
    width: 100%;
    height: 380px;
  }

  .energy-text-cont {
    bottom: 118px;
    height: 60%;
    width: 40%;
  }

  .our-solutions {
    height: auto;
  }
  .overlap-cont {
    margin-top: -18rem;
  }
  /* .second-banner-overlap {
    margin-top: -18rem;
  } */
  .solutions-img-cont {
    height: 100%;
  }
  .better-btn {
    top: 41%;
    left: 44%;
  }
  .f1 {
    font-size: 2.8vw;
  }
  .f2 {
    font-size: 1.8vw;
  }
}

/*-----Calculator page css-----*/

.calculator-title {
  font-size: 46px;
  font-weight: 800;
  text-align: center;
}

.form-control {
  border-radius: 23px;
  border: 1px solid green;
  padding: 9px 10px;
  height: 45px;
  font-size: 14px;
  font-weight: 500;
}

label {
  color: #000000;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 14px;
}

div.btn-container {
  /* display: table-cell; */
  display: flex;
  width: 100%;
  vertical-align: middle;
  text-align: center;
}

div.btn-container i {
  display: inline-block;
  position: relative;
  top: -9px;
}

div.btn-container label {
  display: flex;
  flex-grow: 1;
  font-size: 13px;
  color: #424242;
  font-weight: 500;
}

.btn-color-mode-switch {
  display: inline-block;
  margin: 0px;
  position: relative;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner {
  margin: 0px;
  height: 45px;
  background: var(--btnColor);
  border-radius: 26px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
  display: block;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
  display: flex;
  justify-content: center;
  align-items: center;
  content: attr(data-on);
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  top: 14px;
  right: 20px;
  font-weight: 600;
  color: #000;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
  display: flex;
  justify-content: center;
  align-items: center;
  content: attr(data-off);
  /* width: 20%; */
  height: 41px;
  background: #1c7665;
  color: #fff;
  border-radius: 26px;
  position: absolute;
  left: 2px;
  top: 2px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 6px -2px #111;
  padding: 5px 30px;
}

.btn-color-mode-switch > .alert {
  display: none;
  background: #6ba692;
  border: none;
  color: #fff;
}

.btn-color-mode-switch input[type="checkbox"] {
  cursor: pointer;
  width: 50px;
  height: 25px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner {
  background: #151515;
  color: #fff;
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner:after {
  content: attr(data-on);
  left: 142px;
  background: #1c7665;
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner:before {
  content: attr(data-off);
  right: auto;
  top: 14px;
  left: 14px;
  color: #000;
  font-weight: 600;
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner {
  background: var(--btnColor);
  /*color: #fff;*/
}

.btn-color-mode-switch input[type="checkbox"]:checked ~ .alert {
  display: block;
}

.calculator-kwunit {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  margin-left: 10px;
}

.calculate-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  border: unset;
  background: #010f2e;
  color: #fff;
  padding: 15px 33px;
  font-size: 22px;
  border-radius: 39px;
}
.round-btn {
  border-radius: 39px;
}

.roof-top {
  display: block;
}

/* .solor-install{
      display: none ;
  }  */
.solor-otput-value {
  font-size: 16px;
  color: #000;
  font-weight: 500;
  margin-right: 10px;
  margin-bottom: 0px;
}

.btn-pos {
  position: absolute;
  right: 13px;
  top: 0;
  padding: 11px 14px;
  font-size: 14px;
  border-top-right-radius: 23px;
  border-bottom-right-radius: 23px;
}

.modal-title {
  font-size: 30px;
}

@media (max-width: 768px) {
  .tabs {
    overflow: scroll;
  }
}

/* responsiveness fo calulator start*/

@media screen and (max-width: 991px) {
  .btn-color-mode-switch
    input[type="checkbox"]:checked
    + label.btn-color-mode-switch-inner:after {
    left: 49.6%;
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .btn-color-mode-switch
    input[type="checkbox"]:checked
    + label.btn-color-mode-switch-inner:after {
    left: 37.6%;
    width: 62%;
  }

  /* overlap */
  .overlap-cont {
    margin-top: 0px;
  }
   .second-banner-overlap {
    margin-top: 0px;
  } 
  .img-cont-who{
    height: 163px;
    margin-bottom: 20px;
  }
  .energy-text-cont {
    bottom:40px !important;
    height:auto !important;
    width: auto !important;
  }
  .dp{
    padding: 0 10px;
  }
  .our-solutions{
    height: auto;
    margin-bottom: 20px;
  }
  .solar-power-heading{
    font-size: 2rem;
  }
  .smButton {
    width: max-content;
}
  .energy-cont{
    margin-top: 0px;
  }
  .solarCard{
    height: auto;
  }
  .our-solutions.solar-sec .dp{
    height: auto !important;
  }
}

@media screen and (max-width: 450px) {
  .energy-text-cont {
    bottom: 0;
    height: 40%;
    width: 60%;
  }
}
/* responsiveness fo calulator end*/

.btn_plus {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 266px;
  right: 317px;
  cursor: pointer;
}

a.playBtn {
  width: 80px;
  height: 80px;
  background-color: #76c21b;
  border-radius: 100%;
  border: #fff solid 2px;
  position: relative;
  animation: shadowPulse 1s infinite linear;
}

a.playBtn::before {
  position: absolute;
  content: "";
  border-top: transparent 15px solid;
  border-bottom: transparent 15px solid;
  border-left: #fff 25px solid;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
}

a.playBtn::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: #fff solid 2px;
  border-radius: 100%;
  animation: ringPulse 1.5s infinite linear;
}

@keyframes ringPulse {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }

  10% {
    opacity: 1;
  }

  80%,
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes shadowPulse {
  0% {
    box-shadow: 0 0 8px 6px transparent, 0 0 0 0 transparent,
      0 0 0 0 transparent;
  }

  10% {
    box-shadow: 0 0 8px 6px #9ed63c, 0 0 12px 10px transparent,
      0 0 12px 5px #9ed63c;
  }

  80%,
  100% {
    box-shadow: 0 0 8px 6px transparent, 0 0 0 40px transparent,
      0 0 0 40px transparent;
  }
}

/* slider js css start */
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
/* slider js css end */
/* -------------------------------------------------------------------------------------- */

/* ------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
}
:root {
  --primary: #ec994b;
  --white: #ffffff;
  --bg: #f5f5f5;
}

#d-slider > .swiper-slide {
  width: 30.5% !important;
}

.trending-slide-img img {
  min-width: 26rem;
  max-width: 100%;
  height: 24rem;
  object-fit: cover;
}
.trending-slider .swiper-slide {
  width: 200px; /* Adjust the width as needed */
}

.main-slider-image {
  /* box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 7px 4px; */
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.8);  */
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

/* update */
.solar-card {
  height: 278px !important;
}
