* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: "#eee";
}

.row {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

:root {
  --default: 16px;
  --title: 100px;
  --title2: 60px;
  --heading: 40px;
  --subHeading: 30px;
  --subHeadingsm: 24px;
  --btnBg: #337c64;

  --back: #36403d;
  --back7: #087fda;
  --back1: linear-gradient(290deg, #000000d1, #439a7dbc);
  --footer: linear-gradient(45deg, #1e5d48bc 11.2%, #087fda 89%);
  --back2: linear-gradient(90deg, #f8a11c 0%, #ea8315 39%, #bb0742 100%);
  --txt: #ffffffd1;
  /* --btn: bisque; */
  /* --btn: #9f6578; */
  /* --btn: #9F8C65; */
  /* --btn: #959f65;p */
  --btn: #9f7067;
  /* --btn: #fbf2c4; */
  /* --btn: #334d45; */

  /* --btn: #9f6f65; */
  --btn1: #48675d;
  --gray: #80808046;
  --grey: #80808015;
  --yellow: #2cb2d5;
  --back3: #201f1cf7;
  --icon: linear-gradient(
    60deg,
    hsl(36, 94%, 54%) 0%,
    hsl(42deg 86% 47%) 0%,
    hsl(47deg 86% 41%) 0%,
    hsl(53deg 80% 37%) 1%,
    hsl(60deg 69% 34%) 3%,
    hsl(69deg 60% 35%) 7%,
    hsl(79deg 51% 35%) 13%,
    hsl(91deg 43% 35%) 23%,
    hsl(106deg 36% 34%) 48%,
    hsl(125deg 33% 33%) 88%,
    hsl(141deg 41% 29%) 97%,
    hsl(152deg 47% 25%) 100%,
    hsl(160deg 51% 22%) 100%
  );
  --back4: #bb0742;
  --back6: #4ed260b7;
  --card: linear-gradient(to right, #4fb26e10, #00508d10);
  --logo: white;
  --span: #3d7563;
  --background: linear-gradient(
    90deg,
    hsl(159deg 17% 33%) 0%,
    hsl(162deg 18% 33%) 3%,
    hsl(164deg 20% 33%) 7%,
    hsl(166deg 21% 33%) 11%,
    hsl(168deg 23% 33%) 15%,
    hsl(170deg 24% 33%) 20%,
    hsl(172deg 26% 33%) 25%,
    hsl(173deg 27% 33%) 30%,
    hsl(175deg 29% 33%) 35%,
    hsl(177deg 31% 33%) 40%,
    hsl(178deg 33% 33%) 46%,
    hsl(180deg 34% 33%) 52%,
    hsl(181deg 37% 33%) 57%,
    hsl(182deg 39% 33%) 63%,
    hsl(184deg 42% 33%) 69%,
    hsl(185deg 44% 33%) 74%,
    hsl(186deg 47% 33%) 79%,
    hsl(187deg 49% 34%) 84%,
    hsl(187deg 52% 34%) 89%,
    hsl(188deg 54% 34%) 93%,
    hsl(189deg 57% 34%) 97%,
    hsl(190deg 59% 35%) 100%
  );
  /* --btn1: #275345; */
  /* --back: rgb(230, 230, 230); */
  --mainColor: #f7d909;
  --btnColor: #e2a822e7;
  --hoverColor: #dfb75ae7;
  --green: #1e5d48bc;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

body {
  /* font-family: "Montserrat", sans-serif; */
  position: relative;
  font-size: var(--default);
  overflow-x: hidden !important;
  min-height: 100%;
  background: #eee;
  /* font-optical-sizing: auto; */
  /* font-weight: 500; */
}

/* header */
.banner-text {
  position: relative;
}

.p {
  font-size: var(--default);
}

.banner {
  background: var(--back1);
  height: 100vh;
  background-size: cover !important;
}

.fa-bars {
  color: white;
}

.chatimg > img {
  margin: 0 -110px;
  width: 100px;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.chatimg > img:hover {
  transform: scale(1.1);
}

.header-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 5;
}

section#bottomTape {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

#indexScrollBtn {
  bottom: 120px;
}

.banner-text-animated {
  color: white;
  font-weight: 500;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  line-height: 1.3;
}

.banner-text-animated div {
  overflow: hidden;
  white-space: nowrap;
  /* text-align: center; */
  font-weight: 700;
  font-size: calc(0.9rem + 1.25vw);
}

.banner-text-animated div:first-of-type {
  animation: showup 7s infinite;
}

.banner-text-animated div:last-of-type {
  width: 0px;
  animation: reveal 7s infinite;
}

.banner-text-animated div:first-of-type span {
  color: #feff82;
}

.banner-text-animated div:last-of-type span {
  animation: slidein 7s infinite;
}

@keyframes showup {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slidein {
  0% {
    margin-left: 0px;
  }

  20% {
    /* margin-left: -800px; */
  }

  35% {
    margin-left: 0px;
  }

  100% {
    margin-left: 0px;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
    width: 0px;
  }

  20% {
    opacity: 1;
    width: 0px;
  }

  30% {
    width: 25%;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    width: 100%;
  }
}

.canvas-wrapper {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.canvas-wrapper #canvas,
#metering_canvas {
  height: 100%;
}

#canvas > canvas,
#metering_canvas > canvas {
  max-height: 100% !important;
}

/* index banner animation */
.heading {
  width: 100%;
}

.type {
  line-height: 1;
  font-size: calc(1rem + 6vw);
  font-weight: 600;
  color: #0000;
  background: linear-gradient(-90deg, #fff 0px, #0000 0) 0 0,
    linear-gradient(#fff 0 0) 0 0;
  background-size: calc(var(--n) * 1.05ch) 200%;
  -webkit-background-clip: padding-box, text;
  background-clip: padding-box, text;
  background-repeat: no-repeat;
  animation: t calc(var(--n) * 0.26s) steps(var(--n)) forwards;
}

@keyframes t {
  /* Typewriter animation keyframes */
  from {
    background-size: 0 200%;
  }
}

.banner-text > p {
  display: flex;
  justify-content: center;
  color: #d6cfcf;
  font-size: calc(0.5rem + 1.5vw);
  /* line-height: 2.4rem; */
  padding: 2rem 0 3rem 0;
}

.custom-btn {
  color: var(--txt);
  width: 135px;
  height: var(--heading);
  padding: -2px 25px;
  /* font-family: "Lato", sans-serif; */
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  font-size: var(--default);
}

.button-box {
  margin: var(--default) 0;
}

.btn-9 > a {
  color: var(--txt) !important;
}

.btn-9 {
  background-color: var(--btn);
  border: 1px solid var(--btn);
  box-shadow: 0 0 10px var(--btn), 0 0 5px var(--btn) inset;
  z-index: 2;
  transition: all 0.3s ease;
  overflow: hidden;
  /* color: var(--back); */
}

.custom-btn-green {
  background-color: var(--btn);
  color: var(--txt);
  border: var(--btn);
}

.btn-9:after {
  position: absolute;
  content: " ";
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
}

.btn-9:hover {
  color: var(--btn1);
}

.btn-9:hover:after {
  background: var(--txt);
  border: 6px solid var(--btn);
  -webkit-transform: scale(2) rotate(180deg);
  transform: scale(2) rotate(180deg);
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 1),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 1),
    inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
}

/* .button {
    background: var(--back3);
    padding: 8px 14px;
    border: none;
    outline: none;
    transition: transform 0.3s ease;
    margin-bottom: 2rem;
    font-size: calc(.9*var(--default));
}

.button:hover {
    transform: scale(1.1);
}

.button a {
    color: black;
} */

/* p.wrap{
    position: relative;
} */

img.energypic {
  position: absolute;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  right: 0;
  bottom: 0;
  margin-bottom: 2rem;
}

/* section 1 */

.left-col {
  padding: 5px;
}

.left-col > h2 {
  -webkit-text-stroke: 0.8px var(--txt);
  color: transparent;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: lights 5s 750ms linear infinite;
  font-size: calc(0.9rem + 1.25vw);
}

.section1 {
  background-color: #48675d;
  color: var(--txt);
}

.right-col {
  color: var(--txt);
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 1.2rem;
}

.right-col-child1 {
  /* border-right: 2px solid #ffffff30;   */
  padding: 15px;
}

.right-col-child2 {
  padding: 15px;
}

.card-img-top {
  margin-bottom: 0.4rem;
}

.card-title {
  margin-bottom: 0rem;
}

.card-text {
  line-height: calc(1.2 * var(--default));
  font-size: var(--default);
}

/* section2 */
h2 {
  text-align: center;
  font-size: calc(0.9rem + 1.25vw);
}

.sec2-content {
  color: #36403d;
  font-size: calc(0.9rem + 1.25vw);
  font-weight: 600;
}

.section2-content {
  color: #4fb26e;
  font-size: var(--subHeading);
}

.tile1 {
  border-right: 1px solid rgba(128, 128, 128, 0.516);
}

.tile2 {
  background-repeat: no-repeat;
  background: url("../res/assets/img3.jpg") rgba(82, 171, 238, 0.697);
  background-size: cover;
  background-blend-mode: multiply;
  border-left: 1px solid grey;
  color: var(--txt);
}

/* .sec2-bg{
    background-image: url("../images/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
} */

.left {
  overflow: hidden;
}

.card2 {
  padding-left: 39px;
}

.m-card {
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  overflow: hidden !important;
  z-index: 1 !important;
  background: var(--txt) !important;
  padding: 0.2rem 1.3rem !important;
  height: 250px !important;
}

.m-card.left-card {
  height: 528px !important;
}

.m-card h4 {
  font-size: calc(0.55rem + 1.2vw);
  font-weight: 600;
  margin-top: 10px;
}

.m-card p {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.7s ease-in-out;
  text-align: center;
  font-size: calc(0.9 * var(--default));
}

.m-card:hover p {
  max-height: 1000px;
  transition: max-height 3s ease-in-out;
}

.m-card h4::before {
  content: "\00a0";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 1%;
  height: 1%;
  z-index: -1;
  border-top-left-radius: 50%;
  background-color: #3d756350;
}

.m-card:hover {
  transition: all 0.4s ease-in-out;
  transform: scale(1.02) translateY(-1.5%);
}

.m-card:hover h4::before {
  transition: all 1s ease-in-out;
  width: 120%;
  height: 120%;
}

.right-index .row:first-of-type .col-sm-6:first-of-type .m-card img {
  width: 75px;
}

.right-index .row:first-of-type .col-sm-6:nth-of-type(2) .m-card img {
  width: 75px;
}

.right-index .row:nth-of-type(2) .col-sm-6:nth-of-type(1) .m-card img {
  width: 75px;
}

.right-index .row:nth-of-type(2) .col-sm-6:nth-of-type(2) .m-card img {
  width: 75px;
}

/* .cardMore {
    background: url("../images/img3.jpg") rgba(82, 171, 238, 0.697);
    background-size: cover;
    background-blend-mode: multiply;
    margin-left: 5px;
    width: 427px;
} */

/* section3  */
ul > li {
  list-style: none;
  font-weight: 600;
}

.fa-circle-check {
  font-size: 1.2rem;
  color: #baeeca;
}

.blue-bg {
  /* margin: 0;
    padding: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    color: white;
    background-color: #192E01; */
  background-repeat: no-repeat;
  background: var(--back1);

  color: var(--txt);
  position: relative;
}

.blue-bg .container {
  position: relative;
  z-index: 2;
}

.blue-bg canvas {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

h5 {
  font-size: calc(0.5rem + 1.05vw);
  margin: 0;
}

.Ai-section {
  font-size: calc(0.9rem + 1.25vw);
  text-align: start;
  /* line-height: calc(3*var(--default)); */
}

/* section4 */

.items {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  width: 100%;
  /* background: var(--back1); */
  /* color: var(--txt); */
  /* padding: 4rem; */
}

.item {
  padding: 3rem 2rem !important;
  position: relative;
  border-right: 2px solid rgba(128, 128, 128, 0.392);
  font-size: var(--default);
  cursor: pointer;
  width: 285px;
  min-height: 300px;
}

.feedback-p {
  width: 212px;
}

.item:hover {
  background-color: var(--btn1);
  color: var(--txt);
}

.item > h5 {
  /* color: var(--txt); */
  font-weight: 600 !important;
}

.item h3 {
  font-size: calc(0.75rem + 1.2vw);
  font-weight: 600;
}

.first:hover .feedback-h3 {
  color: var(--txt);
}

.second:hover .feedback-h3 {
  color: var(--txt);
}

.third:hover .feedback-h3 {
  color: var(--txt);
}

.feedback-h3 {
  color: var(--btn1);
}

.item p {
  margin-top: 1rem;
}

.third {
  border-right: none;
}

.img1 {
  border-radius: 50%;
  width: 220px;
  height: 220px;
  left: 0;
  bottom: 0;
  margin: 0 0 -99px 25px;
}

.img2 {
  position: absolute;
  border-radius: 50%;
  width: 170px;
  height: 170px;
  right: 0;
  top: 0;
  margin: -6px 44px;
}

.img3 {
  border-radius: 50%;
  position: absolute;
  width: 100px;
  height: 100px;
  top: 0;
  left: 0;
  margin: -25px 0px;
}

.sec4 .items .item h5 {
  font-size: calc(0.4rem + 0.85vw);
}

/* section5 */
.sec5-bg {
  background: var(--back1);
  background-size: cover;

  color: var(--txt);
  /* margin-top: 7rem; */
}

.about {
  -webkit-text-stroke: 2px var(--txt);
  color: transparent;
  font-size: calc(1rem + 4vw);
  letter-spacing: 0.2rem;
  text-align: start;
  position: relative;
  z-index: 1;
}

.title-about {
  font-size: var(--default);
  margin-top: 6px;
}

.slider-span {
  /* padding: 4rem 3rem; */
  display: flex;
  flex-direction: column;
  vertical-align: middle;
}

.energy1 {
  -webkit-text-stroke: 2px var(--txt);
  color: transparent;
  font-size: calc(1.2 * var(--title2));
  /* letter-spacing: .2rem; */
  text-align: start;
  position: relative;
  z-index: 1;
  margin-top: 1rem;
  /* line-height: 117px; */
}

.consult,
.tech,
.solar {
  -webkit-text-stroke: 2px var(--txt);
  color: transparent;
  font-size: calc(1.4 * var(--title2));
  /* letter-spacing: .2rem; */
  text-align: start;
  position: relative;
  z-index: 1;
  margin-top: 1rem;
  line-height: 86px;
}

/* .para{
    color: var(--txt);
    position: relative;
    z-index: 2;
    text-align: start;
    top: -105px;
    
} */
.para1 {
  color: var(--txt);
  position: relative;
  z-index: 2;
  text-align: start;
  top: -1px;
}

.para > h3 {
  font-size: calc(1.2 * var(--heading));
}

.para1 > p {
  color: var(--txt);
  text-align: start;
  /* margin: 1rem 0; */
  font-size: calc(1 * var(--default));
}

/* a {
  text-decoration: none;
  color: var(--txt);
} */

.sec5-btn {
  text-align: start;
  display: flex;
  padding: 1rem;
}

.home-slider-images {
  display: flex;
  justify-content: center;
}

.home-slider-images > img {
  max-width: 546px;
}

.picture-box {
  display: flex;
  align-items: center;
  gap: 10rem;
}

.picture-a {
  color: var(--btn);
  text-decoration: underline;
  margin-left: 5rem;
  font-weight: 600;
}

.picture {
  background-color: #9cb02a;
  border-radius: 50%;
  width: 60px;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #0e3714;
}

.image-container {
  position: relative;
  display: flex;
}

.p1 {
  left: 2%;
  top: -50%;
}

.p2 {
  left: -29%;
  top: -50%;
}

.p3 {
  left: -61%;
  top: -50%;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: var(--subHeading) !important;
  color: var(--btn1);
}

.home-swiper-section {
  height: 480px;
}

/* section6 */
.blue {
  color: #36403d;
}

.yellow {
  color: var(--span);
}

.green {
  color: #1b6d35;
}

.bg-section {
  background-color: rgba(128, 128, 128, 0.181);
  background-image: url("../res/assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  padding: var(2 * var(--default) 0);
}

.slider1 {
  background-color: transparent !important;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: calc(1.1 * var(--default));
  background: #fff;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  vertical-align: middle;
}

.slider1 .row {
  align-items: center;
}

.slider1 img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* section7 */
.lastSection {
  background-repeat: no-repeat;
  background: var(--back1);
  background-size: cover;
  background-blend-mode: multiply;
  background-position: bottom;
  color: var(--txt);
}

.lastSec > h2 {
  line-height: 2rem;
  font-weight: 600;
  font-size: var(--subHeading);
}

.lastSec > p {
  color: var(--txt);
  font-size: calc(1.1 * var(--default));
  word-wrap: wrap;
}

.link-a {
  display: flex;
  align-items: center;
  justify-content: end;
}

.link-a > a {
  color: var(--txt);
  margin-right: 5px;
}

/* 
.tile {
  align-items: center;
} */
.footer-hr {
  border-top: 1px solid var(--txt);
}

.footer-logo > h5 {
  font-size: calc(0.35rem + 1vw);
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-links h5 {
  font-size: calc(0.3rem + 1.15vw);
  margin: 0;
}

.footer-social > h5 {
  font-size: calc(0.3rem + 1.15vw);
  margin: 0;
}

.footer-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  /* text-align: center; */
}

.footer-menu > li {
  font-weight: normal;
  /* margin-bottom: 10px; */
  font-size: var(--default);
}

.footer-menu > li > a {
  font-size: var(--default);
}

.footer-menu li:last-child {
  margin-right: 0;
}

.footer-menu a {
  color: #fff;
  text-decoration: none;
}

.social-icons {
  list-style: none;
  padding: 0;
  margin: 0;
}

.social-icons li {
  display: inline-block;
  margin-right: 10px;
}

.social-icons li:last-child {
  margin-right: 0;
}

.social-icons a {
  color: #fff;
  text-decoration: none;
  font-size: calc(1.2 * var(--default));
}

.footer-bottom {
  /* margin-top: 20px; */
  text-align: center;
  font-size: calc(0.9 * var(--default));
  padding: 5px;
}

.footer-bottom p {
  margin: 0;
}

.attribute {
  position: absolute;
  bottom: 10px;
}

/* section icon */
.icons {
  position: fixed;
  bottom: 90px;
  right: 32px;
  z-index: 999;
  width: fit-content;
}

.service-consultacy-icon {
  bottom: 105px;
}

.icons > div {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: fit-content;
  margin-left: auto;
}

.icons > div > ul > li > a > i {
  color: white;
  text-align: center;
  background: var(--back1);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: var(--subHeadingsm);
  line-height: 53px;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

/* chatbot */
.chatbot-toggler {
  position: fixed;
  right: 30px;
  bottom: 35px;
  height: 50px;
  width: 50px;
  color: var(--txt);
  background-color: var(--btn1);
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  padding: calc(2 * var(--default));
}

.chatbot-toggler span i {
  font-size: var(--subHeading);
  font-weight: bold;
}

.chatbot {
  position: fixed;
  right: 40px;
  bottom: 100px;
  width: 420px;
  background-color: white;
  border-radius: 15px;
  z-index: 999;
  display: block;
  margin: 20px 20px 0 20px;

  /* transform: scale(0.5); */
  /* pointer-events: none; */
  /* opacity: 0; */
}

.show-chatbot .chatbot {
  transform: scale(1);
  pointer-events: auto;
  opacity: 1;
}

.chatbot header {
  background-color: var(--btn1);
  padding: 8px;
  text-align: center;
  color: var(--txt);
  /* padding: 20px; */
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
}

.chatbot header h2 {
  font-size: var(--subHeadingsm);
  text-align: left;
  letter-spacing: 2px;
  margin: 0;
}

.chatbot header span i {
  font-size: var(--subHeading);
  cursor: pointer;
}

.chatbox {
  height: 370px;
  overflow-y: auto;
  padding: 16px 20px 70px;
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
  -ms-overflow-style: none;
}

.chatbox .chat {
  display: flex;
  text-align: left;
}

.chatbox .chat p {
  color: var(--txt);
  text-align: left;
  max-width: 75%;
  background-color: var(--btn1);
  padding: 10px 16px;
  border-radius: 10px 10px 0 10px;
  font-size: calc(0.9 * var(--default));
  font-weight: 100;
}

.chatbox .incoming p {
  color: black;
  background-color: var(--gray);
  border-radius: 10px 10px 10px 0;
  font-weight: 100;
}

.chatbox .incoming span i {
  background-color: var(--btn1);
  font-size: calc(1.2 * var(--default));
  color: var(--txt);
  padding: 5px 7px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: baseline;
  margin: 0 10px 7px 0;
  align-self: flex-end;
}

.chatbox .outgoing {
  justify-content: flex-end;
  margin: 5px 0;
}

.chat-input {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  gap: 5px;
  /* background-color: var(--txt); */
  padding: 0px 20px;
  border-top: 1px solid var(--btn1);
}

.chat-input textarea {
  border: none;
  outline: none;
  font-size: calc(0.9 * var(--default));
  width: 100%;
  height: 55px;
  resize: none;
  padding: 16px 15px 16px 0;
}

.chat-input span {
  color: var(--btn1);
  cursor: pointer;
  align-self: flex-end;
  height: 55px;
  line-height: 55px;
  font-size: var(--subHeadingsm);
  visibility: hidden;
}

.chat-input textarea:valid ~ span {
  visibility: visible;
}

.tool-tip {
  visibility: visible;
  text-align: center;
  border-radius: 4px;
  top: -10%;
  right: 110%;
  width: 99px;
  background: var(--btn1);
  height: 30px;
  text-align: center;
  padding: 5px 0;
  font-size: 16px;
  font-weight: 500;
}
.tool-tip:after {
  content: " ";
  position: absolute;
  bottom: 10%;
  left: 100%;
  margin-top: 0px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent transparent var(--btn1);
}

/* about us page */
.about-banner {
  overflow: hidden;
  height: 100%;

  color: var(--txt);
  /* background-position: center;/ */
}

.about-bg-img {
  background: var(--back1), url(../res/assets/aboutBanner.png) no-repeat;
  background-size: cover;
  height: 100vh;
}

.about-banner > .row {
  height: 100%;
}

.bannerLeft > h1 {
  font-size: calc(1rem + 3.5vw);
  font-weight: bold;
  color: var(--txt);
}

.banner-left > p {
  /* font-size: calc(0.5 * var(--default)); */
  font-size: calc(2 * var(--default)) !important;
}

.banner-right > h5 {
  font-size: var(--default);
}

/* main section  */
.main-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-heading {
  color: var(--btn2);
  font-size: var(--subHeadingsm);
  font-weight: 600;
}

.main-heading2 {
  font-size: var(--subHeading);
  font-weight: bold;
}

.second-para {
  font-weight: 600;
}

a {
  text-decoration: none;
}

.card1 {
  /* background: url("../res/assets/aboutImg1.png") !important; */
  color: white;
  text-align: center;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  overflow: hidden;
  height: 300px !important;
  width: 280px !important;
  transition: height 0.3s;
  background-blend-mode: multiply;
}

.right-main {
  gap: calc(5 * var(--default));
}

.a-card {
  padding: 0 calc(1.2 * var(--default)) !important;
  line-height: calc(1.4 * var(--default)) !important;
  justify-content: center;
}

.card1:hover {
  height: auto;
  background-color: var(--btn1) !important;
  background-image: none !important;
  transition: height 0.3s ease-in-out;
  /* color: black;      */
}

.card1 .a-card-text {
  display: none;
  opacity: 0;
  /* padding: 10px; */
  /* font-size: calc(1.2*var(--default)); */
}

.card1:hover .a-card-text {
  display: flex;
  opacity: 1;
  transition: all 0.25s ease-in-out;
}

.a-card-title {
  font-weight: bold;
  font-size: var(--heading);
}

.card1:hover .a-card-title {
  display: none;
}

.card2 {
  /* background: url("../res/assets/aboutImg2.png") !important; */
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.card3 {
  background: url("../res/assets/aboutImg3.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.card4 {
  background: url("../res/assets/aboutImg4.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.a-card-title {
  font-size: var(--subHeading);
  font-weight: bolder !important;
}

/* .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */
.pulse a {
  color: var(--txt);
}

.pulse {
  width: 60px;
  height: 60px;
  background: var(--btn1);
  border-radius: 50%;
  color: var(--txt);
  font-size: var(--subHeading);
  text-align: center;
  line-height: 60px;
  /* font-family: verdana; */
  text-transform: uppercase;
  animation: animate 3s linear infinite;
  cursor: pointer;
}

@keyframes animate {
  0% {
    box-shadow: 0 0 0 0 var(--btn), 0 0 0 0 var(--btn);
  }

  40% {
    box-shadow: 0 0 0 50px rgba(255, 109, 74, 0), 0 0 0 0 var(--btn);
  }

  80% {
    box-shadow: 0 0 0 50px rgba(255, 109, 74, 0),
      0 0 0 30px rgba(255, 109, 74, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 109, 74, 0), 0 0 0 30px rgba(255, 109, 74, 0);
  }
}

.swiper-slide {
  text-align: center;
  font-size: calc(1.1 * var(--default));
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* section3 */
.section-3 {
  background: var(--back1);
  padding: calc(2 * var(--default));
}

.section3-col-right {
  color: var(--txt);
}

.section3-col-right > h6 {
  font-size: var(--default);
}

.section3-col-right > h4 {
  font-size: var(--heading);
  font-weight: bolder;
}

.section3-col-right-para1 {
  color: var(--btn);
}

.anchor {
  color: var(--btn);
}

.team-thumb {
  width: calc(4 * var(--default));
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.paragraph {
  margin: 0;
  /* margin-top: calc(1.2*var(--default)); */
  font-size: var(--subHeadingsm);
  color: var(--txt);
  margin-top: 10px;
  font-weight: 600;
  text-align: center;
}

.mySwiper {
  padding-bottom: calc(2 * var(--default)) !important;
}

.container-width {
  margin-left: 2rem !important;
}

.swip {
  overflow: hidden;
}

.slider-energy {
  width: 60px;
}

.Slider-commodity {
  width: 70px;
}

.swip {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: calc(1.1 * var(--default));
  background: #fff;
}

.swiper-slide img {
  display: block;
  margin-bottom: 0;
}

.our-team {
  padding: 30px 0 40px;
  margin-bottom: 30px;
  background-color: #f7f5ec;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.our-team .picture {
  display: inline-block;
  height: 130px;
  width: 130px;
  margin-bottom: 50px;
  z-index: 1;
  position: relative;
}

.our-team .picture::before {
  content: "";
  height: 0;
  border-radius: 50%;
  background-color: var(--btn1);
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.9;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}

.our-team:hover .picture::before {
  height: 100%;
}

.our-team .picture::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--btn1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.our-team .picture img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
}

.our-team:hover .picture img {
  box-shadow: 0 0 0 14px #f7f5ec;
  transform: scale(0.7);
}

.our-team .title {
  display: block;
  font-size: calc(0.9 * var(--default));
  color: #4e5052;
  text-transform: capitalize;
}

.our-team .social {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--btn1);
  position: absolute;
  bottom: -100px;
  left: 0;
  transition: all 0.5s ease 0s;
}

.our-team:hover .social {
  bottom: 0;
}

.our-team .social li {
  display: inline-block;
}

.our-team .social li a {
  /* display: block; */
  padding: 10px;
  font-size: calc(1.1 * var(--default));
  color: white;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}

.our-team .social li a:hover {
  color: var(--btn1);
  background-color: #f7f5ec;
}

.glass,
.transform {
  width: 350px;
  height: 259px;
  border-radius: 10px;
}

.card-image-section {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: calc(2.5 * var(--default)) calc(2.5 * var(--default));
}

.glass {
  background-color: #63897d;
  opacity: 0.5;
  position: relative;
  z-index: 10;
  width: 243px;
  color: var(--txt);
  background: rgb(119 176 104 / 44%);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgb(98 190 81 / 66%);
}

.transform {
  background-color: #337c64;
  position: absolute;
  z-index: 2;
  width: 243px;
  transform-origin: top bottom right;
  transform: rotate(-10deg);
  transition: transform 0.4s ease;
}

.card-image-section:hover > .transform {
  transform: rotate(0deg);
}
.contact-text {
  text-align: left;
}
/* about page end */

/* technology page start */
#myVideo {
  object-fit: cover;
  position: relative;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;

  /* min-width: 100%; */
  /* min-height: 100%; */
}

.tec-vido-banner {
  z-index: 1;
  height: 100vh;
}

.tech-banner {
  overflow: hidden;
  /* height: 89.6vh; */
  color: var(--txt);
}

.tech-banner > .row {
  padding: calc(3 * var(--default)) calc(6 * var(--default))
    calc(6 * var(--default)) calc(6 * var(--default));
}

.tech_banner_head {
  font-size: calc(1rem + 3.5vw);
  font-weight: 700;
  color: #fff;
}

.tech-banner-section img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

#swiper-banner {
  width: 100%;
  height: 400px;
}

#banner-swiper-slide {
  text-align: center;
  font-size: calc(1.1 * var(--default));
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 8rem 0; */
}

#banner-swiper-slide img {
  display: block;
  width: 100%;
  /* height: 80%; */
  object-fit: cover;
}

.tech-h4 {
  font-size: calc(0.8 + 3vw);
}

.slider-banner {
  background: url(../res/assets/techBanner.png) var(--back) no-repeat;
  background-size: cover;
  color: white;
  /* padding: calc(5*var(--default)); */
}

.banner-span {
  color: var(--btn);
}

.banner-div h2,
.banner-span {
  font-size: calc(1.1 * var(--heading));
}

/* tech section */
#tech-swiper {
  width: 100%;
  height: 100%;
}

#tech-swiper-slide > .swiper-slide {
  text-align: center;
  font-size: calc(1.1 * var(--default));
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

#tech-swiper-slide > .swiper-slide > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tech-ul {
  padding: 0;
  margin-top: var(--default);
}

.tech-ul > li > span {
  font-size: var(--default);
}

#tech-card > div {
  text-align: left;
}

#tech-card {
  width: 98%;
  height: 400px;
}

.seperator {
  border-top: 4px solid var(--grey);
  position: relative;
  border-radius: 5px;
}

.line {
  border-top: 4px solid var(--btn1);
  position: absolute;
  width: 40px;
  top: -4px;
  left: 0;
}

#tech-card > div > p {
  font-size: var(--default);
}

.fa-circle {
  font-size: 7px;
  color: var(--btn1);
}

.tech-card-link {
  font-size: var(--default);
  text-decoration: none;
  color: var(--back);
}

.tech-heading {
  font-weight: bold;
}

.card-inner > h5 {
  font-size: calc(1.3 * var(--default));
  font-weight: 500;
}

/* video */
.video-section > img {
  width: 100vw;
  height: 400px;
}

.build-section {
  background: url(../res/assets/techBanner.png) no-repeat;
  background-size: cover;
  padding: calc(5 * var(--default)) calc(2 * var(--default));
  position: absolute;
  bottom: -70px;
  left: 100px;
}

.video-tile > p {
  font-size: calc(1.2 * var(--default));
  color: var(--txt);
}

.video-tile > h4 {
  font-size: var(--heading);
  color: var(--txt);
}

/* logo */
#swiper-2 {
  width: 100%;
  height: 100%;
}

#swiper-slider-2 > .swiper-slide {
  text-align: center;
  font-size: calc(1.1 * var(--default));
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

#swiper-slider-2 > .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider2-logo {
  margin: calc(1.2 * var(--default)) 0;
}

.tech-text-cont {
  background-color: white;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 30%;
  width: 39%;
}

.checked {
  color: orange;
}

.rating {
  color: var(--gray);
  background-color: var(--txt);
  padding: 3rem 1rem;
  border-radius: 7px;
  right: 0;
  bottom: 0;
  margin: 0 calc(1.6 * var(--default)) calc(3 * var(--default));
}

.rating > strong {
  color: black;
  font-size: var(--subHeadingsm);
}

.powerful > h3 {
  font-size: calc(1.15 * var(--subHeadingsm));
  margin: 20px 0;
}

.powerful {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.hr {
  border-top: 3px solid var(--gray);
  position: relative;
  border-radius: 5px;
}

.line1 {
  border-top: 3px solid var(--btn1);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.line2 {
  border-top: 3px solid var(--btn1);
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* project section */
.project-section {
  padding: calc(1 * var(--default)) calc(2.4 * var(--default));
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  color: #000;
}

.project-section > h4 {
  font-size: calc(1.3 * var(--default));
}

.project-section > p {
  font-size: var(--default);
  font-weight: 300;
  margin: 0;
  line-height: 25px;
  text-align: center;
}

.chatbox .chat p {
  font-weight: 400 !important;
}

.project-section > img {
  width: 60px !important;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

/* latest thinking */

.box {
  background: url("../res/assets/techImg1.png");
  overflow: hidden;
  margin: 0 5px 5px 5px;
  border: 1px solid var(--back);
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
}

.cover {
  width: 100%;
  height: 100%;
  background: #00000073;
  position: relative;
  -webkit-transition: all 0.4s cubic-bezier(0.99, 0.99, 0, 0.61);
  padding: 1em;
  color: var(--txt);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  text-align: start;
}

.title {
  text-align: start;
}

.cover .btn {
  padding: 0.5em 1em;
  /* margin: 0 0.5em; */
  background: var(--btn1);
  /* float: right; */
  border-radius: 5px;
}

.btn a {
  color: var(--txt);
  text-decoration: none;
}

.box:hover .left {
  /* -webkit-transition: all 0.4s cubic-bezier(0.99, 0.99, 0, 0.61); */
  left: 0px;
}

.left {
  left: -45rem;
}

.slick-slide {
  margin-left: 15px;
  margin-right: 15px;
}

.slick-prev,
.slick-next {
  background: #222222;
}

.slick-prev:hover,
.slick-next:hover {
  background: #222222;
}

.slick-prev:before,
.slick-next:before {
  font-size: var(--default);
  color: #fff;
  line-height: inherit;
  font-weight: bold;
}

.slick-prev {
  left: -70px;
}

.slick-next {
  right: -70px;
}

.slick-prev:before {
  content: "\f053";
}

.slick-next:before {
  content: "\f054";
}

/* technology page end */
/* solar page start */
:root {
  --mainColor: #f7d909;
  --btnColor: #e2a822e7;
  --hoverColor: #dfb75ae7;
  --green: #1e5d48bc;
}

.heading_of_banner > h3 {
  color: var(--txt);
}

.heading_of_banner > h2 {
  color: var(--txt);
  font-weight: bolder;
  font-size: var(--heading);
}

.heading_of_banner {
  top: 0;
  left: 25%;
  margin-top: calc(5 * var(--default));
}

.solar-left-banner > h1 {
  font-size: calc(0.9 * var(--title));
}

.dp {
  padding: 0 40px;
}

/*pre defined classes*/
/* colors */
.backClr {
  color: var(--green);
}

.btnColor {
  background-color: var(--btnColor);
  transition: all linear 0.4s;
}

.btn:hover {
  background-color: var(--hoverColor);
}

/* font size */
.f1 {
  font-size: 1.6vw;
}

.f2 {
  font-size: 1.2vw;
}

.fontText-12 {
  font-size: 12px;
}

.fontText-14 {
  font-size: 14px;
}

.font-18 {
  font-size: 18px;
}

.font-16 {
  font-size: calc(1 * var(--default));
}

/* main heading */
.main-heading {
  color: #1e5d48bc;
  text-transform: uppercase;
  font-weight: 600;
  font-size: calc(1.1 * var(--default));
}

.greenClr {
  color: #1e5d48bc;
}

.head-line {
  line-height: 1.1;
}

/*pre defined classes end*/
/* ------------------------------------------- */

#mySwiper1 .card {
  min-height: 620px;
}

section {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  /* margin-bottom: 20px; */
}

.about-page {
  background: linear-gradient(290deg, rgba(0, 0, 0, 0.819), #439a7dbc);
}

.second-banner {
  /* background: linear-gradient(290deg, rgba(0, 0, 0, 0.819), #439a7dbc),
    url("../res/assets/solarHeaderBg.jpg") no-repeat 100%; */
  height: 100vh;
  background-size: cover !important;
}

.sec-banner-card {
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.large-card {
  background: #6ba692 !important;
  color: white !important;
  min-height: 350px !important;
}

.solar-power-heading {
  font-size: calc(1rem + 4vw);
}

.Solar-img-cont p {
  font-size: calc(0.55rem + 1.2vw);
}

/* banner section */

.banner-start {
  background: url("../res/assets/aboutBanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0;
}

.head-icon > img {
  height: 51px;
}

/* why section */
.resp-sec2 {
  display: none;
}

.resp-sec-tiles {
  padding: 20px;
  position: relative;
}

.resp-sec-tiles img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.resp-sec-tiles > p {
  position: absolute;
  top: 25%;
  left: 0;
  width: 75%;
  font-weight: 600;
  text-align: left;
  color: #fff;
  font-size: calc(0.45rem + 0.9vw);
  transform: translateX(15%);
  -webkit-transform: translateX(15%);
  -moz-transform: translateX(15%);
  -ms-transform: translateX(15%);
  -o-transform: translateX(15%);
}

/* section who we are */
.who {
  margin-top: 20px;
}

.energy-cont {
  width: 43.4vw;
  height: 30vw;
  position: relative;
}

.img-cont-who {
  background-image: url(../res/assets/solarEnergy.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 90%;
  height: 90%;
}

.energy-text-cont {
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50%;
  width: 40%;
}

.energy-text-cont p {
  margin: 0;
}

/* our-solutions */

.solutions-img-cont {
  color: white;
  /* background: linear-gradient(290deg, rgba(0, 0, 0, 0.819), #439a7dbc),
    url("../res/assets/solarPlantBg.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 510px;
}

.Solar-img-cont {
  color: rgb(0, 0, 0);
}

/* .second-banner-overlap {
  margin-top: -17rem;
} */

.overlap-cont {
  margin-top: -24rem;
}

.hide-cont ul {
  padding-left: 12px;
}

.hide-cont {
  display: none;
}

.show_hide-cont {
  display: block;
}

.hide-cont > ul p {
  font-size: 14px;
}

.hide-cont > ul li {
  padding-left: 10px;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  list-style: disc;
}

/* offer */
.we-offer {
  margin-top: 30px;
}

.we-offer .card {
  background: linear-gradient(290deg, rgba(0, 0, 0, 0.6), #439a7dbc);
  height: 260px;
}

.icon-cont-card {
  background-color: var(--green);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  margin-bottom: 11px;
}

.icon-cont-card-Top {
  background-color: var(--green);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
}

.icon-cont-card-Top > img {
  height: 2.5rem;
  width: 2.5rem;
}

.icon-cont-card > img {
  height: 2.5rem;
  width: 2.5rem;
}

.text-of-card {
  font-size: 14px;
}

.better {
  height: 460px;
  margin-bottom: 30px;
}

.better-img-cont {
  position: relative;
}

.better-img {
  /* border: 2px solid red; */
  background: url("../res/assets/solarPlantBg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 30rem;
}

.better-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  position: absolute;
  top: 41%;
  right: -50px;
}

.better-btn i {
  color: var(--mainColor);
}

button {
  margin: 0 !important;
}

.card.solarCard {
  min-height: 100%;
}

.energy-right-col {
  list-style: circle;
}

.energy-right-col h6 {
  font-size: calc(0.5rem + 0.5vw);
}

/* .better-text{
     
  } */

/* green-energy*/
.green-energy {
  padding: 2vw 0;
  background: linear-gradient(290deg, rgba(0, 0, 0, 0.925), #1c5f49d5),
    url("../res/assets/homeSlideSolar.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.green-energy label {
  color: white;
}

/* awareness */
.awareness {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background: linear-gradient(290deg, rgba(0, 0, 0, 0.819), #439a7dbc),
    url("../res/assets/solar.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.contact-us-Card-solar {
  height: 100%;
}

/* responsivness start */

/* responsivness start */

@media only screen and (min-width: 992px) and (max-width: 1158px) {
}

/*-----Calculator page css-----*/

.calculator-title {
  font-size: 46px;
  font-weight: 800;
  text-align: center;
}

.form-control:focus {
  border: 1px solid var(--btn1);
  box-shadow: 0 0 0 0.1rem var(--btn1);
}

.form-control {
  border-radius: 23px !important;
  border: 1px solid var(--btn1) !important;
  padding: 9px 10px !important;
  height: 45px !important;
  font-size: calc(0.9 * var(--default));
  font-weight: 500;
}

label {
  color: #000000;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: calc(0.9 * var(--default));
}

div.btn-container {
  /* display: table-cell; */
  display: flex;
  width: 100%;
  vertical-align: middle;
  text-align: center;
}

div.btn-container i {
  display: inline-block;
  position: relative;
  top: -9px;
}

div.btn-container label {
  display: flex;
  flex-grow: 1;
  font-size: calc(0.85 * var(--default));
  color: #424242;
  font-weight: 500;
}

.btn-color-mode-switch {
  display: inline-block;
  margin: 0px;
  position: relative;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner {
  margin: 0px;
  height: 45px;
  background: #baeeca;
  border-radius: 26px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
  display: block;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
  display: flex;
  justify-content: center;
  align-items: center;
  content: attr(data-on);
  position: absolute;
  font-size: calc(0.8 * var(--default));
  font-weight: 500;
  top: 14px;
  right: 20px;
  font-weight: 600;
  color: #000;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
  display: flex;
  justify-content: center;
  align-items: center;
  content: attr(data-off);
  /* width: 20%; */
  height: 41px;
  background: #3d7563;
  color: #fff;
  border-radius: 26px;
  position: absolute;
  left: 2px;
  top: 2px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 6px -2px #111;
  padding: 5px 30px;
}

.btn-color-mode-switch > .alert {
  display: none;
  background: var(--back6);
  border: none;
  color: #fff;
}

.btn-color-mode-switch input[type="checkbox"] {
  cursor: pointer;
  width: 50px;
  height: 25px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner {
  background: #151515;
  color: #fff;
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner:after {
  content: attr(data-on);
  left: 241px;
  background: #3d7563;
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner:before {
  content: attr(data-off);
  right: auto;
  top: 14px;
  left: 14px;
  color: #000;
  font-weight: 600;
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner {
  background: #baeeca;
  /*color: #fff;*/
}

.btn-color-mode-switch input[type="checkbox"]:checked ~ .alert {
  display: block;
}

.calculator-kwunit {
  font-size: calc(0.9 * var(--default));
  font-weight: 600;
  color: #ffffff;
  margin-left: 10px;
}

.calculate-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  border: unset;
  background: var(--btn);
  color: #fff;
  padding: 15px 33px;
  font-size: calc(0.9 * var(--subHeadingsm));
  border-radius: 39px;
}

.round-btn {
  border-radius: 39px;
  width: 57%;
}

.roof-top {
  display: block;
}

#solor-install {
  display: none;
}

.solor-otput-value {
  font-size: calc(1 * var(--default));
  color: #000;
  font-weight: 500;
  margin-right: 10px;
  margin-bottom: 0px;
}

.btn-pos {
  position: absolute;
  right: 13px;
  top: 0;
  padding: 11px 14px;
  font-size: calc(0.9 * var(--default));
  border-top-right-radius: 23px;
  border-bottom-right-radius: 23px;
  background-color: #3d7563;
  color: white;
}

.btn-pos:hover {
  background-color: #baeeca;
  color: #3d7563;
}

.modal-title {
  font-size: calc(1 * var(--subHeading));
}

@media (max-width: 768px) {
  .tabs {
    overflow: scroll;
  }
}

/* responsiveness fo calulator start*/

@media screen and (max-width: 991.98px) {
  .btn-color-mode-switch
    input[type="checkbox"]:checked
    + label.btn-color-mode-switch-inner:after {
    left: 49.6%;
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .btn-color-mode-switch
    input[type="checkbox"]:checked
    + label.btn-color-mode-switch-inner:after {
    left: 37.6%;
    width: 62%;
  }

  /* overlap */
  .overlap-cont {
    margin-top: -18rem;
  }

  /* .second-banner-overlap {
    margin-top: -17rem;
  } */

  .sec-banner-card {
    height: 240px;
  }

  .energy-text-cont {
    bottom: 0;
    height: 50%;
    width: 50%;
  }
}

@media screen and (max-width: 450px) {
  /* .second-banner-overlap {
    margin-top: -19rem;
  } */

  .sec-banner-card {
    height: 300px;
  }

  .energy-text-cont {
    bottom: 0;
    height: 40%;
    width: 60%;
  }
}

/* responsiveness fo calulator end*/

.btn_plus {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 266px;
  right: 317px;
  cursor: pointer;
}

a.playBtn {
  width: 80px;
  height: 80px;
  background-color: #76c21b;
  border-radius: 100%;
  border: #fff solid 2px;
  position: relative;
  animation: shadowPulse 1s infinite linear;
}

a.playBtn::before {
  position: absolute;
  content: "";
  border-top: transparent 15px solid;
  border-bottom: transparent 15px solid;
  border-left: #fff 25px solid;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
}

a.playBtn::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: #fff solid 2px;
  border-radius: 100%;
  animation: ringPulse 1.5s infinite linear;
}

@keyframes ringPulse {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }

  10% {
    opacity: 1;
  }

  80%,
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes shadowPulse {
  0% {
    box-shadow: 0 0 8px 6px transparent, 0 0 0 0 transparent,
      0 0 0 0 transparent;
  }

  10% {
    box-shadow: 0 0 8px 6px #9ed63c, 0 0 12px 10px transparent,
      0 0 12px 5px #9ed63c;
  }

  80%,
  100% {
    box-shadow: 0 0 8px 6px transparent, 0 0 0 40px transparent,
      0 0 0 40px transparent;
  }
}

/* slider js css start */
.swiper {
  width: 100%;
  /* height: 100%; */
}

.swiper-slide {
  text-align: center;
  font-size: calc(1.1 * var(--default));
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  } */
/* slider js css end */
/* -------------------------------------------------------------------------------------- */

/* ------------------------- */

#d-slider > .swiper-slide {
  width: 30.5% !important;
}

.trending-slide-img {
  width: 100%;
}

.trending-slide-img img {
  min-width: 26rem;
  max-width: 100%;
  height: 24rem;
  width: 100%;
  object-fit: cover;
}

.trending-slider .swiper-slide {
  width: 200px;
  /* Adjust the width as needed */
}

.main-slider-image {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

/* update */
.solar-card {
  height: 278px !important;
}

/* solar page end*/

/* contact page start */

.pt-60 .pb-60 {
  padding: 60px 0;
}

.cf {
  border: 2px solid var(--gray);
  padding: 2rem;
  border-radius: 20px;
}

.cf label {
  display: block;
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
}

.cf input[type="text"],
.cf input[type="email"],
.cf textarea {
  width: 100%;
  padding: 11px;
  margin-bottom: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 25px;
  outline: none;
  /*box-sizing: border-box;
  */
}

.cf button {
  border-radius: 34px;
  cursor: pointer;
}

.contact-us {
  background: #80808029;
  filter: drop-shadow(0px 3px 96px rgba(0, 0, 0, 0.07));
  box-shadow: 7px 7px 50px 0px rgb(94 137 135);
  padding: 30px 33px;
  border-radius: 16px;
  margin: 2rem 0;
}

.ourtool_slide_title {
  font-size: calc(1rem + 0.5vw);
  font-weight: 500;
  /* line-height: 30px; */
  text-align: left;
  color: #000;
  text-transform: capitalize;
  margin-top: 10px;
}

.ourtool_slide_para {
  font-weight: normal;
  font-size: calc(0.85 * var(--default));
  line-height: 20px;
  text-align: left;
  color: #000;
  padding-bottom: 10px;
}

.ourtools_head {
  font-weight: bold;
  /* line-height: 40px; */
  text-align: left;
  color: var(--btn1);
  margin-bottom: 0 !important;
}

.ourtool_slide_para > li {
  font-weight: 400 !important;
  margin-bottom: 0px;
}

.ourtool_slide_para > li > a > {
  display: flex;
  justify-content: space-between;
}

.ourtool_slide_para > li > a > div > span {
  color: black;
}

.ourtool_slide_para > li > a > div > span > i {
  font-size: calc(1.2 * var(--default));
  border-radius: 50%;
  padding: 5px 6px;
  /* color: black; */
}

.ourtool_slide_para > li > a > div > span > i:hover {
  background-color: var(--btn1);
  /* color: #fff; */
}

.ourtools_sec_bg {
  background-color: white !important;
  background-size: 50%;
  background-position: left;
  display: flex;
  align-items: center;
}

h2 a {
  font-size: calc(0.9rem + 1.25vw);
  text-decoration: none;
  display: inline-block;
  position: relative;
}

/*effect-underline*/
a.effect-underline:after {
  content: "";
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 1px solid;
  margin-top: 10px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}

a.effect-underline:hover:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.footer-list > li > a {
  color: var(--txt);
}

.contact-bg {
  /* background: url(../res/assets/contactBg.png) no-repeat; */
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

/* contact page end*/
/* media page start */
.media-banner-img {
  background: url(../res/assets/mediaBg.png) no-repeat;
  background-size: cover;
  height: 100vh;
}

.tab {
  overflow: hidden;
  border-bottom: 2px solid #40524c;
  margin-bottom: 20px;
  padding-bottom: 11px;
}

.tab button {
  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: calc(1.2 * var(--default));
  font-weight: 500;
}

.tab button:hover {
  background-color: var(--btn1);
  color: var(--txt);
}

.fond {
  margin-bottom: 54px;
}

.tab button.active {
  background-color: var(--btn1);
  color: var(--txt);
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border-top: none;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.chatbox .chat p {
  font-weight: 400 !important;
}

.tabcontent.active {
  display: block;
  opacity: 1;
}

/* .blog-card {
      border: 1px solid #ddd;
      border-radius: 10px;
      margin-bottom: 20px;
      padding: 20px;
      cursor: pointer;
      background-color: var(--txt);
  }
  .blog-card>img{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .blog-card>p{
    padding: 0 10px;
  }
.blog-card>h2{
    font-size: calc(1.3*var(--default));
    font-weight: bold;
    text-align: start;
    color: var(--btn1);
    padding: 0 10px;
    margin-top: calc(1.3*var(--default)) ;
} */
.pagination {
  justify-content: center;
}

.pagination .page-item.active .page-link {
  background-color: var(--btn1);
  border-color: var(--btn1);
  color: #fff;
}

.page-link {
  color: black;
}

/* news */
.News-content {
  background-color: var(--btn1);
  border-left: 6px solid var(--back3);
  padding: 4rem 0;
  /* height: 400px; */
  color: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
}

.News-content img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.Mile-content {
  background-color: var(--blog);

  color: #fff;
}

.mileImg {
  width: 200px;
}

.blogCard {
  margin: 0 50px;
  position: relative;
  margin-top: 75px;
  background-color: #fff;
}

.thumbnail {
  position: absolute;
  top: -100px;
  left: 8px;
  width: 85%;
}

img.left {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.separator {
  margin-top: 10px;
  border: 1px solid #c3c3c3;
}

/* p {
     text-align: justify;
     padding-top: 10px;
     font-size: 0.95rem;
     line-height: 18px;
     color: #4B4B4B;
      position: relative;
 } */

/* h5 {
     position: absolute;
     left: 30px;
     bottom: -120px;
     font-size: 6rem;
     color: #C3C3C3;
 } */

.blog-ul {
  display: flex;
  gap: 7px;
  margin-right: 10px;
  justify-content: end;
  position: absolute;
  top: 20px;
  right: 0;
  flex-direction: column;
  /* border-radius: 2px solid var(--grey); */
}

.blog-ul > li > span {
  list-style: none;
  /* padding-right: 40px; */
  color: #7b7b7b;
  /* font-size: 16px; */
}

.fa-2x {
  font-size: calc(0.9 * var(--subHeadingsm));
  font-weight: 500;
}

.fa-2x:hover {
  color: var(--btn1);
}

.fab > p {
  font-size: var(--default);
  color: white;
  font-weight: 100 !important;
}

.read-more {
  text-decoration: underline;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 20px 40px 0;
  font-weight: bold;
}

/* CSS */
.expanded {
  height: auto;
  max-height: none;
  overflow: visible;
}

/* responsive */
.Milecard {
  float: left;
  width: 100%;
}

.Milecard .menu-content a {
  color: var(--txt);
}

.Milecard .menu-content span {
  position: absolute;
  left: 50%;
  top: 0;
  font-size: 10px;
  font-weight: 700;
  /* font-family: "Open Sans"; */
  transform: translate(-50%, 0);
}

.Milecard .wrapper {
  background-color: var(--txt);
  min-height: 400px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 19px 38px rgba(var(--black), 0.3),
    0 15px 12px rgba(var(--black), 0.2);
}

.Milecard .wrapper:hover .data {
  transform: translateY(0);
}

.Milecard .data {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.Milecard .data .content {
  padding: 1em;
  position: relative;
  z-index: 1;
}

.MileCard .title {
  margin-top: 10px;
}

.Mile .text {
  height: 100px;
  margin: 0;
}

/* First example styles */
.example-1 .wrapper {
  background: url(../res/assets/aboutImg4.png) 20% 1% / cover no-repeat;
  border-radius: 6px;
  background-position: center;
}

.example-1 .date {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--bermuda);
  color: var(--txt);
  padding: 0.8em;
}

.example-1 .date span {
  display: block;
  text-align: center;
}

.example-1 .date .day {
  font-weight: 700;
  font-size: calc(1 * var(--subHeadingsm));
  text-shadow: 2px 3px 2px rgba(var(--black), 0.18);
}

.example-1 .date .month {
  text-transform: uppercase;
}

.example-1 .date .month,
.example-1 .date .year {
  font-size: 12px;
}

.example-1 .content {
  background-color: white;
  box-shadow: 0 5px 30px 10px rgba(var(--black), 0.3);
}

.example-1 .title a {
  color: lighten(var(--black), 50%);
}

.example-1 .menu-button {
  position: absolute;
  z-index: 999;
  top: 16px;
  right: 16px;
  width: 25px;
  text-align: center;
  cursor: pointer;
}

.example-1 .menu-button span {
  width: 5px;
  height: 5px;
  background-color: lighten(var(--black), 50%);
  color: lighten(var(--black), 50%);
  position: relative;
  display: inline-block;
  border-radius: 50%;
}

.example-1 .menu-button span::after,
.example-1 .menu-button span::before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  background-color: currentColor;
  position: absolute;
  border-radius: 50%;
}

.example-1 .menu-button span::before {
  left: -10px;
}

.example-1 .menu-button span::after {
  right: -10px;
}

.example-1 .menu-content {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  transition: transform 0.3s;
  transform: translateY(0);
}

.example-1 .menu-content li {
  width: 33.333333%;
  float: left;
  background-color: var(--bermuda);
  height: 60px;
  position: relative;
}

.example-1 .menu-content a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: calc(1 * var(--subHeadingsm));
}

.example-1 .menu-content span {
  top: -10px;
}

.review_head > h2 {
  font-size: calc(0.9rem + 1.25vw);
  margin: 0;
  font-weight: bold;
  color: var(--btn1);
  margin-top: 10px;
  text-align: left;
}

.review_wrap {
  padding: 0 20px;
  position: relative;
  outline: none;
  text-align: left;
  /* width: 25%; */
  /* margin-left: 299px; */
  min-height: 160px;
  margin-top: 290px;

  a {
    color: #333;
    text-decoration: none;
  }

  .review_comment {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    p {
      height: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      /* line-height: 22px; */

      &.active {
        height: auto;
        /* overflow: hidden; */
      }
    }

    span {
      width: 100%;
      color: var(--btn1);
      font-weight: bold;
      margin-top: 12px;
    }
  }
}

.feedback_title {
  display: inline-block !important;
  font-size: calc(0.95 * var(--subHeadingsm));
  margin-top: 0;
  border-bottom: none !important;
}

/* media page end*/

/* energy advisory page start */

.energy-advisory-banner {
  background: url("../res/assets/energyBanner.png") no-repeat center bottom;
  background-size: cover;
  background-blend-mode: multiply;
  height: 100vh;
}

.advisory-main > .container-fluid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.energy-left-col {
  border-right: 3px solid var(--btn);
}

.advisory-main > .container > .vl {
  border-left: 3px solid var(--btn);
  height: calc(3rem + 8vw);
}

.energy-service-list > li {
  list-style: disc;
  font-weight: 500;
  padding: 0;
}

.energy-service-list {
  padding: 20px;
}

.eng-lower-div {
  background: var(--back1);
}

.arrow {
  background: white;
  color: black;
  width: 56px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(1 * var(--subHeading));
  position: absolute;
  bottom: -10px;
  left: 50%;
  cursor: pointer;
}

.advisory-sec2-p {
  font-size: calc(0.55rem + 1.2vw);

  font-weight: 500;
  text-align: center;
}

.energy-advisory-section2-row i {
  font-size: 12px;
  margin-top: 2px;
  margin-right: 12px;
}

/* -------------------------------------- */
/* ManPower Page Start */

.manpower-banner {
  background: linear-gradient(290deg, rgba(0, 0, 0, 0.819), #439a7dbc),
    url(../res/assets/manpoer.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.manP {
  padding: 0 9rem;
}

.video-box {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: white;
}

.video-box > i {
  cursor: pointer;
  color: var(--green);
}

.inner-content-manpower {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90vh;
}

.half-circle-cont {
  position: relative;
}

.half-circle {
  position: absolute;
  bottom: -70px;
  right: -50px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--green);
}

.half-circle > a {
  margin-bottom: 35px;
  margin-right: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.half-circle > a > img {
  width: 32%;
}

.manpower-cards {
  overflow: hidden;
  margin-top: -6rem;
}
.manpower-cards p {
  text-align: justify;
}

.manpower-service-cont {
  width: 43.4vw;
  height: 400px;
  position: relative;
}

.img-cont-who-manpower {
  background-image: url(../res/assets/manpowerImg4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 90%;
  height: 90%;
}

.manpower-icon-who {
  font-size: 12px;
}

.energy-text-cont-manpower {
  background-color: white;
  position: absolute;
  top: 87px;
  right: 0;
  height: 40%;
  width: 50%;
}
.manpower-card {
  cursor: pointer;
  animation: myAnim 0.3s ease 0s 1 normal forwards;
}

/* btn style */

/* CSS */
.button-4 {
  appearance: none;
  background-color: #fafbfc;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0,
    rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292e;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: calc(0.9 * var(--default));
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}

.button-4:hover {
  background-color: #f3f4f6;
  text-decoration: none;
  transition-duration: 0.1s;
}

.button-4:disabled {
  background-color: #fafbfc;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959da5;
  cursor: default;
}

.button-4:active {
  background-color: #edeff2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}

.button-4:focus {
  outline: 1px transparent;
}

.button-4:before {
  display: none;
}

.button-4:-webkit-details-marker {
  display: none;
}

/* responsive start */

@media screen and (max-width: 991px) {
  .manP {
    padding: 0 1rem;
  }

  .manpower-service-cont {
    width: 100%;
    height: 400px;
  }
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 762px) {
  .card-border-manpower {
    border-right: none;
    border-bottom: 2px solid black;
    margin-bottom: var(--default);
  }
}

/* responsive end */
/* ManPower Page End */
/* -------------------------------------- */
/* consultancy division */
.division-consultancy-banner {
  height: 100vh;
  background: var(--back1), url("../res/assets/consultancyBanner.jpg") no-repeat;
  background-size: cover;
}

.consultz_card_section_card {
  padding: 20px;
  min-height: 350px;
  display: flex;
  align-items: center;
  max-width: 88%;
}

.consultz_card_section_card {
  cursor: pointer;
  animation: myAnim 0.3s ease 0s 1 normal forwards;
}

.consultz_card_section_card > div > h3 {
  font-size: calc(1 * var(--subHeading));
  color: var(--btn1);
  font-weight: bold;
  margin-bottom: 1rem;
}

.consult-col-hover > .card:hover {
  background: var(--back1);
  color: var(--txt);
  border: none;
}

.consult-col-hover a {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.consult-col-hover:hover a {
  background-color: transparent;
  height: 100%;
  width: 100%;
  transition: all 0.3 ease-in-out;
  -webkit-transition: all 0.3 ease-in-out;
  -moz-transition: all 0.3 ease-in-out;
  -ms-transition: all 0.3 ease-in-out;
  -o-transition: all 0.3 ease-in-out;
}

.consult-col-hover:hover a i {
  visibility: hidden;
}

@keyframes myAnim {
  0% {
    box-shadow: 0 0 0 0 rgb(94 137 135);
  }

  100% {
    box-shadow: -12px 12px 20px -12px rgb(94 137 135);
  }
}

.consult-col-hover > .card:hover div > h3 {
  color: var(--txt);
}

.consult-col-hover > .card:hover a {
  color: var(--txt);
  background: transparent;
  font-weight: bold;
}

.consult-col-hover div p {
  font-size: calc(1 * var(--default));
  margin-top: calc(1.2 * var(--default));
}

.consultz_card_section_card > div > h5 {
  font-size: calc(1.3 * var(--default));
  font-weight: 800;
  margin-top: calc(1 * var(--default));
}

.consultz_card_section_card > a {
  color: var(--txt);
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--btn1);
  width: 58px;
  height: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 109%;
  border-bottom-right-radius: 6px;
}
.consultz_card_section_card > a > i {
  font-size: calc(1.2 * var(--default));
}
.consult-sec2 {
  z-index: 2;
  bottom: -4rem;
  left: 7%;
  text-align: center;
  position: absolute;
  z-index: 2;
  max-width: 1152px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.consult-right-col {
  background: var(--btn1);
  color: var(--txt);
  display: flex;
  align-items: center;
}
.text-dark {
  /* padding: 2rem; */
  text-align: start;
}
.net_target_first_child {
  width: 40%;
}

.consult_left_img {
  object-fit: contain;
  width: 81%;
  height: 100%;
}

.net_target_second_child {
  width: 60%;
}

.net-target {
  left: -117px;
  padding-right: 3rem;
}

/* justify content (text) */
p {
  /* text-align: justify; */
  font-size: var(--default);
}

.p5Canvas {
  position: absolute;
  z-index: 9;
  width: 400px;
  height: 400px !important;
}

/* metering page */
.metering-header {
  background-image: url(../res/assets/meteringSol.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

#metering-1 {
  padding: 10px;
  padding-right: 10px;
  position: absolute;
  top: 18.5%;
  right: 14%;
  color: white;
  font-weight: 600;

  p {
    font-size: calc(0.95 * var(--default));
  }
}

#metering-2 {
  padding: 10px;
  padding-right: 10px;
  position: absolute;
  bottom: 24%;
  left: 6.7%;
  color: white;
  font-weight: 600;

  p {
    font-size: calc(0.95 * var(--default));
  }
}

#metering-3 {
  padding: 10px;
  padding-right: 10px;
  position: absolute;
  bottom: 12%;
  left: 33%;
  color: white;
  font-weight: 600;

  p {
    font-size: calc(0.95 * var(--default));
  }
}

#metering-4 {
  padding: 10px;
  padding-right: 10px;
  position: absolute;
  bottom: 9%;
  right: 22%;
  color: white;
  font-weight: 600;

  p {
    font-size: calc(0.95 * var(--default));
  }
}

#metering-6 {
  padding: 10px;
  padding-right: 5px;
  position: absolute;
  top: 29%;
  left: 11%;
  color: white;
  font-weight: 600;

  p {
    font-size: calc(0.95 * var(--default));
  }
}

#metering-5 {
  padding: 10px;
  padding-right: 10px;
  position: absolute;
  top: 14%;
  left: 39%;
  color: white;
  font-weight: 600;

  p {
    font-size: calc(0.95 * var(--default));
  }
}

#metering-7 {
  padding: 10px;
  padding-right: 10px;
  position: absolute;
  bottom: 38%;
  right: 5%;
  color: white;
  font-weight: 600;

  p {
    font-size: calc(0.95 * var(--default));
  }
}

#metering-h3 {
  padding: 10px;
  padding-right: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  p {
    font-size: calc(0.95 * var(--default));
  }
}

.metering-section {
  overflow: hidden;
  position: relative;
  justify-content: center;
}

.title-overlay {
  position: absolute;
  left: -17%;
  top: 37.5%;
  height: 130px;
  width: 590px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--back1);
  z-index: -1;
  padding: 5px;
  font-size: calc(2.5rem + 2vw);
  color: white;
  margin: 0;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
}

.metering-left {
  width: 80%;
}

.metering-right {
  position: absolute;
  transform: translateY(-3%);
  right: 0;
  width: 40%;
  z-index: -1;
  -webkit-transform: translateY(-3%);
  -moz-transform: translateY(-3%);
  -ms-transform: translateY(-3%);
  -o-transform: translateY(-3%);
}

.metering-right > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.metering-section1-para > p {
  font-size: calc(1 * var(--default));
}

.metering-card {
  /* background: url("../images/about-img/img1.png") !important; */
  /* color: white; */
  text-align: center;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  overflow: hidden;
  transition: height 0.3s;
  background-blend-mode: multiply;
  min-height: 280px;
  font-size: 0.9rem;
  padding: 1rem;
}

.a-card-text {
  font-size: 0.9rem;
}

.metering-card:hover {
  height: auto;
  background-color: var(--btn1) !important;
  background-image: none !important;
  text-align: justify;
  /* color: black;      */
}

.metering-card .a-card-text {
  display: none;
  text-align: center;
}

.metering-card:hover .a-card-text {
  display: block;
  color: white;
}

.metering-card:hover .a-card-title {
  display: none;
}

.metering-card > .a-card-title {
  font-weight: bold;
  font-size: calc(1.3 * var(--default)) !important;
}

.card1:hover .a-card-title {
  display: none;
}

.mertering-col {
  margin-bottom: calc(2 * var(--default));
  min-width: 250px;
  min-height: 100%;
}

.meter-col-5 {
  margin-left: 11%;
}

.section-bg {
  position: relative;
  /* Needed for positioning the overlay */
  padding: 20px 0;
  background: url(../res/assets/meteringBg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.section-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
}

.section-bg-in {
  position: relative;
  z-index: 3;
}

.footer-about {
  font-size: 11px;
}

.BoxWrapper {
  column-gap: 50px;
  row-gap: 100px;
  margin-top: 100px;
}

.BoxWrapper .imgBox {
  width: 40%;
  border: 13px solid #337c64;
  background-color: #ffffffee;
  position: absolute;
  padding: 20px;
  top: 0;
  border-radius: 50%;
  transform: translateY(-65px);
  -webkit-transform: translateY(-65px);
  -moz-transform: translateY(-65px);
  -ms-transform: translateY(-65px);
  -o-transform: translateY(-65px);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.BoxWrapper .imgBox img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.boxStyle {
  position: relative;
  width: 280px;
  min-height: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 10px;
  text-align: center;
  border-radius: 12px;
  border: 1px solid #fce4ec;
  padding: 20px;
  padding-top: 90px;
  background-color: rgba(255, 255, 255, 0.678);
}

.boxStyle h5 {
  margin-bottom: 10px;
  font-size: calc(16px + 0.3vw);
}

.boxStyle p {
  text-align: left;
}

.consult-col-hover > .card:hover {
  background: var(--back1);
  color: var(--txt);
  border: none;
}

@keyframes myAnim {
  0% {
    box-shadow: 0 0 0 0 rgb(94 137 135);
  }

  100% {
    box-shadow: -12px 12px 20px -12px rgb(94 137 135);
  }
}

.consult-col-hover > .card:hover div > h3 {
  color: var(--txt);
}

.consult-col-hover > .card:hover a {
  color: var(--txt);
  background: transparent;
  font-weight: bold;
}

.consult-col-hover div p {
  font-size: 14px;
}

.consultz_card_section_card > div > h5 {
  font-size: calc(0.95 * var(--default));
  font-weight: 800;
  margin-top: 17px;
}

.consultz_card_section_card > a {
  color: var(--txt);
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--btn1);
  width: 58px;
  height: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 109%;
}

.consult-right-col {
  background: var(--btn1);
  color: var(--txt);
  display: flex;
  align-items: center;
}

.target-right-col {
  background: var(--back1);
}

.net_target_first_child {
  width: 40%;
}

.consult_left_img {
  object-fit: contain;
  width: 81%;
  height: 100%;
}

.net_target_second_child {
  width: 60%;
}

.net-target {
  left: -117px;
  padding-right: 3rem;
}

/* justify content (text) */
/* p {
  text-align: justify;
} */

.p5Canvas {
  position: absolute;
  z-index: 9;
  width: 400px;
  height: 400px !important;
}

.consultz_card_section_card > h3 {
  font-size: calc(1 * var(--subHeading));
  color: var(--btn1);
}

.consultz_card_section_card > h5 {
  font-size: calc(1 * var(--default));
  font-weight: 600;
  margin-top: 5px;
}

/* .consult-sec2 {
  position: absolute;
  z-index: 2;
  bottom: -4rem;
  padding: 2rem 0;
} */

.consult-right-col {
  background: var(--btn1);
  color: var(--txt);
  display: flex;
  align-items: center;
}

/* Service-tech page */

#background {
  background: var(--back1);
  height: 100vh;
  overflow: hidden;
  position: relative;
}

#background .top {
  position: absolute;
  top: 120px;
  left: 0;
  text-align: center;
  color: #fff;
  width: 100%;
  z-index: 2;
}

#background .top h1 {
  font-size: calc(1rem + 3.5vw);
  font-weight: 700;
}

#background .banner-left {
  padding-top: 50px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  overflow: hidden;
}

#background .banner-left img {
  object-fit: contain;
  width: 150%;
  height: 150%;
  background-blend-mode: multiply;
  transform: translateX(-150px);
  -webkit-transform: translateX(-150px);
  -moz-transform: translateX(-150px);
  -ms-transform: translateX(-150px);
  -o-transform: translateX(-150px);
}

#background .banner-right {
  position: absolute;
  padding-left: 50px;
  top: 0;
  right: 0;
  height: 100%;
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

#background canvas {
  width: 100%;
  max-height: 100%;
}

#background .design {
  transform: scale(0.75) rotateY(-30deg) rotateX(45deg) translateZ(4.5rem);
  transform-origin: 50% 100%;
  transform-style: preserve-3d;
  box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.25);
  transition: 0.6s ease transform;
  background: var(--back1);
  width: 300px;
  height: 300px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
  padding: 20px;
}

#background .design:hover {
  transform: scale(1);
}

#background .design::before,
#background .design::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.6s ease;
}

#background .design::before {
  transform: translateZ(4rem);
  border: 5px solid var(--hoverColor);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

#background .design::before:hover {
  transform: translateZ(0);
}

#background .design::after {
  transform: translateZ(-4rem);
  background: var(--back1);
  box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

#background .design::after:hover {
  transform: translateZ(-1px);
}

canvas.connecting-dots {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

a.scrollButton {
  width: 100%;
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 80px;
  text-align: center;
  color: #fff;
  font-size: calc(0.3rem + 1.25vw);

  pointer-events: auto;
  text-decoration: none;
}

a.scrollButton span {
  pointer-events: inherit;
  position: absolute;
  top: 20px;
  left: 49%;
  width: calc(0.3rem + 1.25vw);
  height: calc(0.3rem + 1.25vw);
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb07 2s infinite;
  animation: sdb07 2s infinite;
  opacity: 0;
  box-sizing: border-box;
}

a.scrollButton span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

a.scrollButton span:nth-of-type(2) {
  top: 36px;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

a.scrollButton span:nth-of-type(3) {
  top: 52px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

@-webkit-keyframes sdb07 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes sdb07 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

a.scrollButton span:first-child::before {
  position: absolute;
  top: 0;
  left: -25px;
  z-index: -1;
  content: "";
  width: 44px;
  height: 44px;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  opacity: 0;
  -webkit-animation: sdb03 3s infinite;
  animation: sdb03 3s infinite;
  box-sizing: border-box;
}

@-webkit-keyframes sdb03 {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  60% {
    box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes sdb03 {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  60% {
    box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

a.contactUsButton {
  position: absolute;
  /* z-index: 99; */
  bottom: 170px;
  width: 100%;
  text-align: center;
  color: black;
}

a.contactUsButton span {
  border-radius: 12px;
  padding: 8px 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.techStack article .content {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.596);
}

.techStack article .layer {
  right: 0;
  bottom: 0;
  height: 100%;
  width: 0px;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.techStack article .layer.active {
  width: 100%;
  opacity: 1;
  background: var(--back1);
  background-color: #fff;
  padding: 20px;
  color: white;
}

.techStack .layer.active .tech-ul {
  list-style: none;
}

.techStack .layer.active .tech-ul li span .fa-circle {
  color: white;
}

.techStack article .moreButton {
  padding: 5px 10px;
  background-color: #5c9c8b;
  color: #fff;
  border-radius: 10px;
  border: none;
}

.techStack article .backButton {
  padding: 5px 10px;
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  border: none;
}

.techStack .left-tile,
.techStack .right-tile {
  row-gap: 100px;
}

.techStack .right-tile {
  transform: translateY(120px);
}

.techStack .container {
  margin-top: 50px;
  margin-bottom: 120px;
}

.techStack .container .row {
  margin-bottom: 80px !important;
  column-gap: 80px;
}

.tech-ul {
  padding: 0;
  margin-top: var(--default);
}

.tech-ul > li > span {
  font-size: calc(0.93 * var(--default));
}

.tech-ul > li {
  font-weight: 400;
  font-size: calc(0.93 * var(--default));
  text-align: start;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 5px;
}

.endNote {
  background-color: #ffffffd1;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 10px;
  color: #000;
}

.endNote h4 {
  font-size: calc(0.55rem + 1.1vw);
  margin-bottom: 10px;
}

.endNote p {
  font-size: var(--default);
  font-weight: 300;
  text-align: start;
  margin: 0;
}

/* consultancy-services */

div.consultancy-banner {
  height: 100vh;
  width: 100%;
  position: relative;
  background-image: url(../res/assets/service-consultancy-banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.content-wrapper {
  height: 100%;
  width: 100%;
}

.content-wrapper > div.row {
  height: 100%;
  width: 100%;
}

.content-wrapper .rightContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.content-wrapper .leftContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.leftContainer .bannerText {
  min-height: 280px;
  width: 100%;
  position: relative;
  padding: 30px;
}

.leftContainer .bannerText h1 {
  font-size: calc(1rem + 3.5vw);
}

.leftContainer .bannerText h3 {
  font-size: calc(1rem + 2vw);
}

.leftContainer .bannerText p {
  font-style: italic;
  font-size: calc(0.55rem + 1.2vw);
}

.bannerTextParagraph2 {
  font-size: calc(0.1rem + 1.2vw) !important;
  text-align: start;
  font-style: normal !important;
}

.content-wrapper .rightContainer img {
  width: 100%;
  object-fit: contain;
  object-position: bottom;
  position: relative;
}

a.contactButton {
  text-decoration: none !important;
}

.introTape {
  background-image: linear-gradient(to top, #439a7dbc 0%, #fff 100%);
  background-color: #00000023;
  background-blend-mode: multiply;
  transform: translateY(-5px);
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
}

#multipleStrings {
  width: 100%;
  padding: 30px 20px;
  height: auto;
  font-family: sans-serif;
  margin: 20px auto;
  color: #333;
  font-weight: 600;
  font-size: calc(1.1 * var(--default));
}

.tiles.container .row {
  padding: 10px;
}

.tiles.container .row .col-sm-12 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 10px;
}

.tiles.container .row .col-md-4 img {
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.services.container .row .col-sm-12 {
  position: relative;
}

.services.container .row .col-sm-12 h2 {
  margin-bottom: 10px;
  text-align: start;
}

.services.container .row .col-sm-12 ul {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.services.container .row .col-sm-12 ul li {
  flex: 0 0 calc(50% - 20px);
  left: 0;
  position: relative;
  color: #111;
  list-style: none;
  margin: 4px 10px;

  border-left: 2px solid #439a7dbc;
  transition: 0.5s;
  cursor: pointer;
}

.services.container .row .col-sm-12 ul li:hover {
  left: 10px;
  /* left: 0; */
}

.services.container .row .col-sm-12 ul li span {
  position: relative;
  padding: 8px;
  padding-left: 12px;
  display: inline-block;
  z-index: 1;
  transition: 0.5s;
}

.services.container .row .col-sm-12 ul li:hover span {
  color: #fce4ec;
}

.services.container .row .col-sm-12 ul li:before {
  content: "";
  position: absolute;
  width: calc(100% + 10px);
  height: 100%;
  background: #439a7dbc;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.5s;
}

.services.container .row .col-sm-12 ul li:hover:before {
  transform: scaleX(1);
}

.smButton {
  width: calc(140px + 2.5vw);
  background: #337c64;
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: var(--default);
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em #40524c;
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
  cursor: pointer;
}

.smButton .icon {
  background: #baeeca;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.7em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em #48675d;
  right: 0.3em;
  transition: all 0.3s;
}

.smButton:hover .icon {
  width: calc(100% - 0.6em);
}

.smButton .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: #48675d;
}

.smButton:hover .icon svg {
  transform: translateX(0.1em);
}

.smButton:active .icon {
  transform: scale(0.95);
}

.smButton2 {
  width: calc(140px + 2.8vw);
  background: #baeeca;
  color: #0e3714;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: var(--default);
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em #9fb9b1;
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
  cursor: pointer;
}

.smButton2 .icon {
  background: #3d7563;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.7em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em #48675d;
  right: 0.3em;
  transition: all 0.3s;
}

.smButton2:hover .icon {
  width: calc(100% - 0.6em);
}

.smButton2 .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: white;
}

.smButton2:hover .icon svg {
  transform: translateX(0.1em);
}

.smButton2:active .icon {
  transform: scale(0.95);
}

.smcustom {
  width: calc(175px + 3vw);
}

.sendButton {
  width: calc(80px + 3vw);
  background: var(--btnBg);
  color: white;
  font-family: inherit;
  padding: 0.35em;
  font-size: var(--default);
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em #40524c;
  overflow: hidden;
  position: relative;
  height: 2.8em;
  cursor: pointer;
  justify-content: center;
}

.solarCard {
  padding: 10px !important;
}

.solarCardWrapper {
  overflow: hidden;
  border-radius: 25px;
}

.solarCardWrapper .solarCardBg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
}

.solarCardWrapper .cardLayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 130%;
  height: 130%;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--back1);
  color: #fff;
  transform: rotate(-15deg) translateX(-25%) translateY(40%);
  transition: all 0.35s ease-in-out;
}

.layerWrapper {
  position: relative;
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardIcon {
  position: absolute;
  height: 60px;
  width: 60px;
  top: -30px;
  left: 35%;
  background: #000;
  padding: 10px;
  transform: rotate(15deg);
  border-radius: 12px;
}

.cardTitle {
  width: 100%;
  font-size: 22px;
  position: absolute;
  top: 15%;
  right: -80px;
  transform: rotate(15deg);
  opacity: 1;
  visibility: visible;
}

.cardText {
  opacity: 0;
  visibility: hidden;
  font-size: var(--default);
  text-align: left;
}

.solarCardWrapper:hover .cardLayer {
  transform: rotate(0deg) translateX(-10%) translateY(-10%);
  transition: all 0.35s ease-in-out;
}

.solarCardWrapper:hover .cardLayer .cardText {
  opacity: 1;
  visibility: visible !important;
  transition: all 0.35s ease-in-out;
}

.solarCardWrapper:hover .cardLayer .cardTitle {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

/* career page */
.career-banner {
  background-image: url(../res/assets/career-banner.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.footer-logo h5 {
  margin-bottom: 10px;
}

.footer-menu {
  margin-top: 10px;
}

.footer-menu li {
  margin-bottom: 5px !important;
}

.footer-menu h5 {
  margin: 10px 0 !important;
}

.footer-social h5 {
  margin-bottom: 10px !important;
}

#about-swiper {
  overflow: visible !important;
}

/* #about-swiper .swiper-wrapper {
  overflow: hidden !important;
} */

#about-swiper .swiper-button-next {
  right: -50px;
  left: inherit;
  background-color: #00000081;
  padding: 40px 25px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

#about-swiper .swiper-button-prev {
  left: -50px;
  right: inherit;
  background-color: #00000081;
  padding: 40px 25px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.cd-timeline__container .cd-timeline__block:nth-child(odd) .text-component {
  border-left: 80px solid #4fb26e8f;
}

.cd-timeline__container .cd-timeline__block:nth-child(even) .text-component {
  border-right: 80px solid #4fb26e8f;
}

ul.office > * {
  width: 50%;
  padding: 0;
}

ul.office button {
  color: var(--btn1);
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

ul.office button:hover {
  color: var(--txt);
  background: var(--btnBg);
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

ul.office button.active {
  color: var(--txt);
  background-color: transparent !important;
  background: var(--btnBg) !important;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}
