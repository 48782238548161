@media (max-width: 490px) {
  /* technology */
  :root {
    --default: 14px;
  }

  .pull-right {
    float: none;
  }
  .consult_banner{
    height:100vh !important;
  }
  input {
    width: 100%;
  }

  label {
    width: 100%;
    display: inline-block;
    float: left;
    clear: both;
  }

  li,
  p {
    font-size: calc(0.85 * var(--default));
  }

  input.btn {
    margin: 1.5em 0 0.5em;
  }

  /* h1 {
    font-size: 2.25em;
  }

  h3 {
    font-size: 1.125em;
  } */

  li small {
    display: none;
  }

  .banner-div {
    margin: 0 !important;
    padding: 0 !important;
  }

  .banner-div h2,
  .banner-span {
    font-size: 16px !important;
  }

  .banner-div > p {
    font-size: 14px;
  }

  .btn-9 {
    margin-bottom: 10px !important;
  }

  .navbar-nav > li > a:hover {
    color: white;
    transform: scale(1) !important;
  }

  .navbar-nav > li > a:hover {
    color: white;
    transform: scale(1) !important;
  }

  a.nav-link:hover {
    color: white;
    /* background-color: #ffffff41; */
    border-radius: 15px;
    padding: 0 2px;
  }

  .bannerLeft > h1 {
    font-size: calc(1.5 * var(--default)) !important;
  }

  .bannerLeft > h5 {
    font-size: calc(1 * var(--default));
    margin-bottom: 15px;
  }
  .bannerLeft > p{
    font-size: 10px;
    padding-top: 10px !important;
  } 
  .tech-banner > .row {
    padding: calc(2 * var(--default)) calc(6 * var(--default))
      calc(3 * var(--default)) calc(6 * var(--default)) !important;
  }

  #tech-card {
    height: 340px !important;
  }

  .powerful {
    margin-top: 2rem !important;
  }

  .powerful > h3 {
    margin: 10px 0 !important;
    font-size: calc(1.7 * var(--default)) !important;
  }

  .powerful > p {
    margin: 0 !important;
  }
 
}

@media (min-width: 767px) and (max-width: 900px) {
  .box {
    height: 400px !important;
  }
  .banner{
    height: 50vh;
  }
  .home-slider-images {
    margin-top: 10px;
  }
  .home-slider-images > img {
    max-width: 400px;
  }
  .left-section-pow {
    text-align: center !important;
  }
.card_left_section>.col-sm-6{
  padding-left: 0 !important;
}
.card_left_section>.col-sm-6>.m-card :nth-child(1){
margin-right: 10px;
}
.ab-banner{
  height: 40vh !important;
}

  .powerful {
    margin-top: 2rem !important;
  }

  .powerful > h3 {
    margin: 10px 0 !important;
    font-size: calc(1.7 * var(--default)) !important;
  }

  .powerful > p {
    margin: 0 !important;
  }

  .banner-div h2,
  .banner-span {
    font-size: calc(1 * var(--default)) !important;
  }

  .banner-para {
    font-size: 14px !important;
  }

  img.energypic {
    display: none;
  }

  .para1 > p {
    font-size: calc(1 * var(--default)) !important;
    /* margin-top: 10px; */
    text-align: center;
  }

  .consult {
    font-size: 2rem;
  }

  .lastSec > h2 {
    font-size: 1rem !important;
  }

  .lastSec > p {
    font-size: 0.8rem !important;
  }

  .sec2-bg {
    margin-top: 20px !important;
  }

  .main-left > p {
    font-size: 12px;
  }

  .a-card-title {
    font-size: calc(2 * var(--default)) !important;
  }
}

@media (max-width: 328px) {
  .about {
    font-size: 2rem !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .items {
    margin: 20px 0;
  }

  .item {
    padding: 1.2rem !important;
    width: 305px;
    min-height: 200px;
    border-right: none;
    border-bottom: 2px solid rgba(128, 128, 128, 0.392);
  }

  .item:first-child {
    border-right: 2px solid rgba(128, 128, 128, 0.392);
  }

  .item:last-child {
    border: none;
  }
/* .banner{
  height:50vh;
} */

  .picture-box {
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
  }

  .right-main {
    gap: calc(4 * var(--default));
  }

  img.energypic {
    width: 180px;
    height: 180px;
  }

  .card1 {
    width: 260px !important;
  }

  .we-offer .card {
    height: 300px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .card1 {
    width: 270px !important;
  }

  /* img.energypic {
    width: 220px;
    height: 220px;
    bottom: 0;
  } */

  .item {
    padding: 2rem !important;
    width: 250px;
    min-height: 242px;
  }
}

@media (max-width: 390px) {
  .mertering-col {
    margin-left: 0 !important;
  }

  .mertering-left > .row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .metering-right {
    display: none;
  }
}

@media only screen and (min-width: 1600px) {
  .boxStyle {
    min-height: 360px;
  }

  #mySwiper1 .card {
    min-height: 670px;
  }
}

@media only screen and (max-width: 1599.98px) {
  .boxStyle {
    min-height: 350px;
  }
}

@media only screen and (max-width: 1399.98px) {
  :root {
    --default: 14px;
  }

  .tech-ul > li > span {
    font-size: calc(1 * var(--default));
  }

  .tech-ul > li {
    font-size: calc(0.85 * var(--default));
  }

  /* solar */

  .boxStyle {
    min-height: 315px;
  }

  .cardTitle {
    font-size: 20px;
  }

  #mySwiper1 .card {
    min-height: 610px;
  }

  .btn-pos {
    top: 1px;
  }

  /* media */
  .review_wrap {
    margin-top: 220px;
  }

  /* metering */
  .metering-section {
    justify-content: end;
  }

  .title-overlay {
    left: -21%;
    top: 39%;
    height: 110px;
    width: 590px;
  }

  .metering-left {
    width: 90%;
  }
}

@media only screen and (max-width: 1199.98px) {
  /* .banner{
    height:62vh;
  } */
  .tech-ul {
    height: 200px;
    overflow: auto;
  }

  /* solar */

  .second-banner {
    height: 140vh;
  }

  .second-banner-overlap > .row {
    height: 100%;
  }

  .sec-banner-card {
    height: 280px;
  }

  .boxStyle {
    min-height: 310px;
  }

  .cardIcon {
    position: absolute;
    height: 45px;
    width: 45px;
    top: -25px;
    left: 30%;
  }

  .cardTitle {
    width: 100%;
    font-size: 18px;
    position: absolute;
    right: -60px;
  }

  .cardText {
    font-size: 12px;
  }

  #mySwiper1 .card {
    min-height: 595px;
  }

  /* metering page */

  .title-overlay {
    left: -56%;
    top: 44%;
    height: 120px;
    width: 1200px;
  }

  #metering-1 {
    top: 16.5%;
    right: 14%;

    p {
      font-size: calc(0.8 * var(--default));
    }
  }

  #metering-2 {
    padding: 10px;
    padding-right: 10px;
    position: absolute;
    bottom: 26%;
    left: 6.7%;
    color: white;
    font-weight: 600;

    p {
      font-size: calc(0.8 * var(--default));
    }
  }

  #metering-3 {
    padding: 10px;
    padding-right: 10px;
    position: absolute;
    bottom: 14%;
    left: 33%;
    color: white;
    font-weight: 600;

    p {
      font-size: calc(0.8 * var(--default));
    }
  }

  #metering-4 {
    padding: 10px;
    padding-right: 10px;
    position: absolute;
    bottom: 13%;
    right: 22%;
    color: white;
    font-weight: 600;

    p {
      font-size: calc(0.8 * var(--default));
    }
  }

  #metering-6 {
    padding: 10px;
    padding-right: 5px;
    position: absolute;
    top: 29%;
    left: 11%;
    color: white;
    font-weight: 600;

    p {
      font-size: calc(0.8 * var(--default));
    }
  }

  #metering-5 {
    padding: 10px;
    padding-right: 10px;
    position: absolute;
    top: 14%;
    left: 39%;
    color: white;
    font-weight: 600;

    p {
      font-size: calc(0.8 * var(--default));
    }
  }

  #metering-7 {
    padding: 10px;
    padding-right: 10px;
    position: absolute;
    bottom: 40%;
    right: 5%;
    color: white;
    font-weight: 600;

    p {
      font-size: calc(0.8 * var(--default));
    }
  }

  #metering-h3 {
    padding: 10px;
    padding-right: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    p {
      font-size: calc(0.8 * var(--default));
    }
  }

  .metering-section1-para > p {
    font-size: calc(0.7 * var(--default));
  }

  .net_target_first_child {
    width: 40%;
    max-height: 275px;
  }

  .net_target_second_child {
    /* width: 60%; */
    max-height: 275px;
    font-size: calc(0.85 * var(--default));
  }

  .metering-right {
    transform: translateY(-2%);
    width: 35%;
    -webkit-transform: translateY(-2%);
    -moz-transform: translateY(-2%);
    -ms-transform: translateY(-2%);
    -o-transform: translateY(-2%);
  }

  .metering-right > img {
    object-fit: cover;
  }

  /* energy advisory */
  .round-btn {
    width: 65%;
  }

  .division-consultancy-banner {
    height: 85vh;
  }

  /* media */
  .review_wrap {
    margin-top: 160px;
  }

  /* news */
  .News-content {
    padding: 1rem 0;
    padding-bottom: 2rem;
  }
}

@media only screen and (max-width: 991.98px) {
  :root {
    --default: 14px;
  }
  /* .left-col > h2{
        -webkit-text-stroke: 0px var(--txt);
        color: #fff;
  } */
  /* .section-4-image{
    display: none;
  } */
   .sec4_right_col{
    display: none;
   }
   .para1>div>p{
    text-align: center;
   }
   .main_ai>.Ai-section{
    margin-top: 0 !important;
  
  }
  .main_ai>p{
    font-size: 14px;
    font-weight: 400;
  }
  .ai-list{
    font-size: .83rem;
  }
  body {
    font-size: var(--default);
  }

  .feedback-h3 span {
    font-size: 20px;
  }

  .sec4 .items .item h5 {
    font-size: 16px;
  }

  .mySwiper {
    padding-top: 30px;
  }

  .s-about-img {
    margin-left: -72px;
  }

  .banner-left > h1 {
    font-size: calc(4 * var(--default));
  }

  .card1 {
    margin-right: calc(2 * var(--default));
    margin: 0;
  }

  .card-title {
    font-size: var(--default);
  }

  .card-text {
    font-size: calc(0.9 * var(--default));
  }

  img.energypic {
    width: 150px;
    height: 150px;

    margin-bottom: 0.2rem;
  }

  .items {
    margin: 20px 0;
    flex-direction: column;
  }

  .item {
    padding: 1.2rem !important;
    width: 100%;
    min-height: 170px;
    border-right: none;
    border-bottom: 2px solid rgba(128, 128, 128, 0.392);
  }

  .item:first-child {
    border-right: none;
  }

  .item:last-child {
    border: none;
  }

  .button-box {
    margin: 9px 0;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .about {
    font-size: 2.5rem;
    text-align: center;
  }

  .energy1,
  .consult,
  .tech {
    font-size: 2rem !important;
    line-height: 3rem;
  }

  .para > h3 {
    font-size: 2.2rem;
  }

  .card1 {
    width: 100% !important;
  }

  .right-main {
    gap: 0 !important;
    justify-content: center;
  }

  .para1 > p {
    font-size: calc(1 * var(--default));
    text-align: center;
    margin-bottom: 0;
  }

  .home-swiper-section {
    height: 100%;
  }

  .banner-left {
    display: none !important;
  }

  .banner-right {
    width: 100vw !important;
  }

  header.consultancy-banner {
    background-position: 100%;
  }

  .leftContainer .bannerText {
    /* min-height: 380px; */
    background-color: #00000087;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
  }

  a.contactUsButton {
    bottom: 120px;
  }

  .img-cont-who {
    width: 100%;
    height: 100%;
  }

  .energy-cont {
    margin: 60px 0;
    width: 100%;
    height: 380px;
  }

  .energy-text-cont {
    bottom: 0;
    height: 60%;
    width: 40%;
  }

  .our-solutions {
    height: 400px;
  }

  .overlap-cont {
    margin-top: -18rem;
  }

  /* solar */

  .second-banner {
    height: 100%;
  }

  .sec-banner-card {
    height: 250px;
  }

  .second-banner-overlap > .row {
    height: auto;
  }

  .solutions-img-cont {
    height: 100%;
  }

  .better-btn {
    top: 41%;
    left: 44%;
  }

  .f1 {
    font-size: 2.8vw;
  }

  .f2 {
    font-size: 1.8vw;
  }

  .we-offer .card {
    height: 260px;
  }

  .footer-menu > li > a {
    font-size: 12px !important;
    margin-bottom: 7px !important;
  }

  .footer-about {
    font-size: 12px;
  }

  .footer-social > p {
    font-size: 12px;
  }

  #mySwiper1 .card {
    min-height: 530px;
  }

  .large-card {
    min-height: 100% !important;
  }

  /* energy advisory */
  .energy-left-col {
    border-bottom: 3px solid var(--btn);
    border-right: none;
  }

  .round-btn {
    width: 100%;
  }

  /* metering */
  .net_target_first_child {
    width: 35%;
    max-height: 250px;
  }

  .net_target_second_child {
    width: 65%;
    max-height: 200px;
    font-size: calc(0.85 * var(--default));
  }

  .meter-col-5 {
    margin-left: 0;
  }

  .boxStyle {
    min-height: 310px;
  }

  .whyImg {
    width: 110%;
    max-width: 110%;
  }

  #metering-3 {
    left: 36%;
  }

  #metering-6 {
    left: 12%;
  }

  #metering-1 {
    top: 20%;
    right: 7%;
  }

  #metering-4 {
    right: 14%;
  }

  #metering-2 {
    left: 7.7%;
  }

  #metering-7 {
    right: -3%;
    bottom: 38%;
  }

  .metering-left {
    width: 100%;
  }

  .resp-sec {
    display: none;
  }

  .resp-sec2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .title-overlay {
    display: none;
  }

  /* media */
  .blogCard {
    width: 501px;
    align-items: center;
  }

  .fond {
    display: flex;
    justify-content: center;
  }

  /* division consultancy */
  .consult-target-img {
    width: 100%;
  }

  .consult-sec2 {
    bottom: -5rem;
    left: 0;
  }

  .division-consultancy-banner {
    height: 92vh;
  }
.scrollButton{
  display: none !important;
}
  /* technology */
  .box {
    background-position: center;
    height: 300px;
    margin: 10px;
  }

  /* consultancy */
  .bannerText {
    margin-top: 15vh;
  }

  #tileContainer {
    padding: 30px;
  }

  #tileContainer > .row:first-child {
    margin-bottom: 30px !important;
  }

  /* manpower */
  .manP {
    margin-top: 30px !important;
  }

  .img-cont-who-manpower {
    width: 100%;
    height: 100%;
  }

  /* media */
  .review_wrap {
    margin-top: 270px;
  }

  .blogCard {
    margin-top: 100px;
  }

  .cd-timeline__container .cd-timeline__block:nth-child(odd) .text-component {
    border-right: 80px solid #4fb26e8f;
    border-left: none;
  }
  .banner-text > p {
    padding: 1rem 0 0 0;
  }
}

@media only screen and (max-width: 767.98px) {
  :root {
    --default: 14px;
  }

  .container-width {
    margin-left: 0rem !important;
  }

  .navbar-nav {
    align-items: start !important;
    background-color: var(--txt);
    color: balck !important;
  }

  .relative-text {
    font-size: 1.5rem;
  }

  .header-wraper {
    padding-top: 0 !important;
  }

  img.energypic {
    position: absolute;
    width: 89px !important;
    height: 89px !important;
    right: 12px;
    margin-bottom: 1rem;
  }

  .lastSec > h2 {
    font-size: 1.5rem;
  }

  .lastSec > p {
    font-size: 1rem;
  }

  .copyright {
    font-size: 0.7rem;
  }

  .slider-span {
    padding-top: 0rem;
  }

  .aboutWrapper {
    text-align: center;
  }

  .para > h3 {
    font-size: 4.8vw;
    text-align: center;
  }

  .about {
    font-size: 10vw !important;
    text-align: center;
    /* line-height: 15vw; */
  }

  .para {
    top: -75px;
  }

  .picture-box {
    gap: 0;
  }

  .image-container {
    display: none;
  }

  .left-card {
    height: 200px !important;
    padding: 2rem;
  }

  .Custom_padding_Nav {
    gap: 0 !important;
    justify-content: center;
    font-size: 14px;
    margin-top: 12px;
    width: 100% !important;
  }

  .navbar-nav > li > a {
    color: black !important;
  }

  .Custom_padding_Nav a {
    transition: transform 0.3s ease-in-out;
  }

  .Custom_padding_Nav a:hover {
    transform: scale(0.95);
  }

  .home {
    min-width: 70px !important;
  }

  .section1-pright {
    font-size: 14px;
  }

  .sec2-bg {
    margin: 40px 0;
  }

  .items {
    flex-direction: column;
    margin: 10px 0;
    margin: 30px 0;
  }

  .icons > div > ul > li > a > i {
    width: 40px;
    height: 40px;
    font-size: 17px;
    line-height: 42px;
  }

  .sec-margin {
    margin-top: 2rem;
    padding: 0 !important;
  }

  .section6 > h2 {
    font-size: 1.7rem;
  }

  .section6 > p {
    font-size: 0.7rem;
  }

  .button {
    padding: 1px 10px;
    /* width: 100%; */
  }

  .button > a {
    font-size: 14px;
  }

  .picture-box {
    justify-content: center;
  }

  .section-4-image {
    display: none;
  }

  .card1 {
    width: 100% !important;
    height: 280px !important;
  }

  .card h4 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 20px;
    /* padding-bottom: 10px; */
  }

  .card {
    min-height: 280px;
    padding: 15px;
  }

  .collaboration {
    margin-top: 10px !important;
  }

  .third {
    border: none !important;
  }

  /* solar */
  .solar-power-heading {
    margin-top: 50px;
  }

  #metering-1 {
    top: 16% !important;
    left: 10.5% !important;
    font-size: 10px !important;
  }

  .center {
    display: flex;
    justify-content: center;
  }

  .main-heading {
    font-size: var(--default);
  }

  .a-card-title {
    font-size: calc(2 * var(--default)) !important;
  }

  .cardIcon {
    height: 80px;
    width: 80px;
    top: -35px;
    left: 30%;
  }

  .cardTitle {
    width: 100%;
    font-size: 22px;
    right: -80px;
  }

  .cardText {
    font-size: 14px;
  }

  #mySwiper1 .card {
    min-height: 480px;
  }

  #background .top {
    top: 140px;
  }

  .boxStyle {
    min-height: 250px;
    width: 80%;
  }

  .BoxWrapper .imgBox {
    width: 120px;
  }

  .techStack .container .row {
    margin-bottom: 60px !important;
  }

  .techStack .container .row {
    row-gap: 60px;
  }

  .techStack .container .row img {
    width: 100%;
  }

  .techStack .right-tile {
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
  }

  .techStack .container .row:last-child {
    margin-bottom: 0px !important;
  }

  .tech-ul {
    height: auto;
    overflow: auto;
  }

  /* services-consultancy */
  .rightContainer {
    display: none !important;
  }

  /* division consultancy */
  .first-main-sec {
    margin-left: 0 !important;
  }

  .consult-sec2 > div {
    padding: 0 10px;
  }

  .consult-sec3 {
    padding-left: 2.5rem;
  }

  .consult-right-col > div {
    padding: calc(3 * var(--default)) calc(1 * var(--default));
    text-align: center;
    align-items: center;
  }

  .dp {
    padding: 0;
  }

  .banner-left > p {
    /* font-size: calc(0.5 * var(--default)); */
    font-size: 12px !important;
  }

  .consult-sec2 {
    bottom: -6rem;
    left: 0;
  }

  .about-banner > .row {
    padding: calc(4 * var(--default)) 0;
  }

  .first-main-sec > .container {
    padding: 0;
  }

  .net-target {
    position: static !important;
    /* margin: 20px 0; */
    padding-top: 10px !important;
  }

  .net_target_first_child {
    width: 62% !important;
  }

  .custom-btn {
    width: 100px;
    height: 40px;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .footer-content {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;
  }

  .footer-menu {
    text-align: left;
    margin-top: 3px;
    margin-bottom: 10px;
  }

  .footer-menu li {
    display: block;
    margin-bottom: 10px;
  }

  .footer-logo h5 {
    font-size: 16px;
  }

  .footer-links h5 {
    font-size: 16px;
    margin-bottom: 0px;
  }

  .footer-menu li {
    margin-bottom: 0;
  }

  .footer-menu h5 {
    margin-bottom: 5px !important;
    font-size: 16px;
  }

  .footer-social h5 {
    margin-bottom: 10px !important;
    font-size: 16px;
  }

  .consultz_card_section_card {
    width: 100%;
  }

  /* media */

  .blogCard {
    margin: 0;
    margin-top: 100px;
  }

  /* news */
  .News-content {
    margin-bottom: 0px;
  }

  /* energy advisory */
  .advisory-main > .container > .vl {
    display: none;
  }

  /* home page */

  .card.m-card {
    min-height: 250px;
  }
}

@media only screen and (max-width: 575.98px) {
  /* .banner {
    height: 50vh;
  } */

  .type {
    font-weight: 600;
  }

  .banner-text-animated div {
    font-weight: 500;
  }

  header.consultancy-banner {
    height: 60vh;
  }

  .bannerText {
    margin-top: 11vh;
  }

  #myVideo {
    height: 50vh;
  }

  .inner-content-manpower {
    height: 50vh;
  }

  .scrollButton {
    display: none;
  }

  .left-col > h2 {
    -webkit-text-stroke: 0px var(--txt);
    color: #fff;
  }

  .banner-text > p {
    padding: 1rem 0 0 0;
  }
/* 
  .about-banner > .row {
    padding-bottom: 0;
  } */

  .item {
    padding: 1.2rem !important;
    width: 100%;
    min-height: 120px;
    border-right: none;
    border-bottom: 2px solid rgba(128, 128, 128, 0.392);
  }

  .division-consultancy-banner {
    height: 75vh;
  }

  .chatbot {
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    z-index: 999;
    display: block;
    margin: 0;
  }

  .chatbot header {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
  }

  /* service-tech */
  .techStack .container {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  #tileContainer {
    padding: 0;
  }

  /* solar */

  .boxStyle {
    min-height: 250px;
  }

  .solarCard {
    width: 300px;
    height: 300px;
  }

  .cardIcon {
    height: 50px;
    width: 50px;
    top: -30px;
    left: 30%;
  }

  .cardTitle {
    width: 100%;
    font-size: 18px;
    right: -70px;
  }

  .cardText {
    font-size: 12px;
  }

  /* metering */
  .resp-sec-tiles {
    height: 150px;
  }

  .mertering-col {
    display: flex;
    justify-content: center;
  }

  .metering-card {
    width: 325px;
    height: 150px !important;
  }

  .BoxWrapper .imgBox {
    width: 100px;
    top: 15px;
  }

  /* media */
  #blogContainer .col-md-12.col-lg-6.p-0 {
    display: flex;
    justify-content: center;
  }

  .fond {
    width: 350px;
  }

  .review_wrap {
    margin-top: 130px;
  }

  /* contact Us */
  .contact-us {
    padding: 15px;
  }

  .energy-advisory-section2-row i {
    font-size: 22px;
  }

  .energy-right-col h6 {
    font-size: 12px;
  }

  .cd-timeline__container .cd-timeline__block:nth-child(odd) .text-component {
    border-right: 60px solid #4fb26e8f;
  }

  .cd-timeline__container .cd-timeline__block:nth-child(even) .text-component {
    border-right: 60px solid #4fb26e8f;
  }
}

@media only screen and (max-width: 450px) {
  /* media */
  .blogCard {
    height: auto;
    width: 100%;
  }

  .right {
    margin: 7rem 2rem 0rem;
  }

  .division-consultancy-banner {
    height: 60vh;
  }
  .contact-bg{
    height: 30vh !important;
  }
  .target-right-col >p{
    padding-left: 10px;
  }
  .net-target{
    text-align: center;
  }
  .para_text>p{
    padding-right: 0 !important;
  }
}

@media only screen and (max-height: 650px) {
  #background .design {
    width: 240px;
    height: 240px;
  }

}
@media only screen and (max-width:576px){
  body{
    font-size: 14px;
  }
  .banner-text-animated div{
    font-size: calc(0.9rem + .7vw);
  }
  .left-card{
    margin-bottom: .5rem;
  }
  .para1>div>p{
    text-align: center !important;
  }
  .home-slider-images {
    margin-top: 10px;
  }
  .home-slider-images > img {
    max-width: 400px;
  }
  .about{
    font-size: 20px;
  }
   .consult_banner{
    height:50vh !important;
  }
  .bannerLeft > h1{
    margin:0;
    font-size: calc(0.9rem + .7vw);
  }
 
  .s-about-content {
    margin-top: 2rem;
  }
  .contact-bg{
    height: 30vh !important;
  }
  .tec-vido-banner{
    height: 50vh !important;
  }
}
@media only screen and (min-width:577px) and (max-width:992px){
  .banner{
    height: 50vh;
  }
  .ab-banner{
    height: 40vh;
  }
  .scrollButton{
    display: none;
  }
  .bannerLeft > h1{
    margin:0;
  }
  .consult_banner{
    height:40vh !important;
  }
  #contactUsForm >.row>.col-12>.card{
    justify-content: start !important;
  }
 
}
@media only screen and (max-width:1024px){
  .ab-banner{
    height: 24vh !important;
  }
  .scrollButton{
    display: none;
  }
  .contact-bg{
    height: 20vh ;
  }
  .tec-vido-banner{
    height: 100vh ;
  }
  .consult_banner{
    height:60vh;
  }

}

@media only screen and (min-width:768px) and (max-width:912px){
  .b_c_fix{
    height:36px
  }
  .consult_banner{
    height:60vh;
  }
  .second-banner-overlap {
    margin-top: 1rem !important;
}


}
