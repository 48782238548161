#menu {
  cursor: pointer;
  display: none;
}

.top-bar,
.btm-bar {
  position: absolute;
  width: 21px;
  height: 2px;
  background: white;
  left: 9px;
  top: 11px;
  border-radius: 10px;
  transition: all 500ms cubic-bezier(1, -1, 0.5, 2);
}

.btm-bar {
  top: 27px;
}

.mid-bar {
  stroke-linecap: round;
  stroke: #fff;
  stroke-dasharray: 0 100 40 400;
  stroke-dashoffset: 101;
  transition: all 750ms ease;
}

.navbarSm {
  position: absolute;
  top: 0;
  left: 0px;
  z-index: 5;
  padding: 10px;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
}

.navbarSm:hover {
  background-color: #48675d;
}

nav.navbarSm .navList {
  column-gap: 25px;
}

nav.navbarSm a {
  text-decoration: none;
  color: #fff;
  font-weight: 400;
  font-size: calc(1.05 * var(--default));
}

nav.navbarSm ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a.navLink {
  padding: 10px;
}

a.navLink.dropdownLink {
  position: relative;
}

a.navLink.dropdownLink::after {
  content: "";
  height: 0;
  width: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateX(-50%);
  padding-bottom: 10px;
}

@media only screen and (max-width: 991.98px) {
  #menu.open .top-bar {
    transform-origin: 22px;
    transform: translateX(-2px) rotate(-45deg);
    transition: all 0.75s cubic-bezier(1, -1.5, 0.5, 2);
  }

  #menu.open .btm-bar {
    transform-origin: 22px;
    transform: translateX(-2px) rotate(45deg);
    transition: all 0.75s cubic-bezier(1, -1.5, 0.5, 2);
  }

  #menu.open .mid-bar {
    stroke-dasharray: 0 120 251 251;
    stroke-dashoffset: 1;
  }

  nav.navbarSm {
    padding-bottom: 10px;
  }

  nav.navbarSm .navList {
    height: 0;
    transition: height 0.3s ease;
    overflow: hidden;
    row-gap: 20px;
  }

  nav.navbarSm .navList > li:first-child {
    margin-top: 30px;
  }

  nav.navbarSm .navList.navOpen {
    height: 95vh;
    transition: height 0.3s ease;
    overflow: hidden;
  }

  nav.navbarSm .navList li {
    width: 100%;
    text-align: center;
  }

  nav.navbarSm .navList li {
    width: 100%;
  }

  a.navLink {
    padding: 0px 10px;
  }

  .navItem {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  nav.navbarSm a {
    font-size: calc(16px + 2vw) !important;
  }
}
a {
  cursor: pointer;
}

/* new navbar*/

.navbar {
  background-color: #333;
  padding: 10px;
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.nav-item {
  margin-right: 10px;
  position: relative;
}

.nav-link {
  color: #fff;
  text-decoration: none;
  padding: 10px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #444;
  padding: 0;
  margin-top: 0;
  width: 250px;
}

.dropdown-item {
  position: relative;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu .dropdown-item:hover .dropdown-menu {
  display: block;
  left: 100%;
  top: 0;
}

.dropdown-menu a {
  color: #000 !important;
}

.dropdown-item:hover {
  background-color: #48675d !important;
  width: 100%;
}

.dropdown-menu .dropdown-item:hover > a {
  margin: 1;
  padding: 1;
  color: white !important;
}

.dropdown-submenu::after {
  content: "";
  height: 0;
  width: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #000;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.dropdown-submenu:hover::after {
  border-left: 5px solid #fff;
}
.dropdown-item a,
.dropdown-menu .dropdown-item a {
  display: block;
  width: 100%;
}

@media only screen and (max-width: 991.98px) {
  .dropdown-menu {
    position: relative; /* Change to relative to push down other menu items */
    display: none; /* Initially hide the dropdown */
    width: 100%; /* Ensure dropdown takes full width */
    margin-top: 10px; /* Add some space above dropdown */
  }

  .dropdown-item {
    position: relative; /* Maintain relative position for items */
  }

  .dropdown:hover > .dropdown-menu {
    display: block; /* Show dropdown when hovering over parent */
  }

  .dropdown-menu .dropdown-item:hover .dropdown-menu {
    display: block; /* Show nested dropdowns on hover */
    position: relative; /* Keep relative positioning for nested dropdowns */
    top: 0; /* Reset top position */
    left: 0; /* Align nested dropdowns to the left of their parent */
    margin-top: 10px; /* Add some space above nested dropdowns */
  }

  .dropdown-submenu::after {
    content: "";
    height: 0;
    width: 0;
    border-left: 5px solid transparent; /* Hide left border */
    border-right: 5px solid transparent; /* Hide right border */
    border-top: 5px solid #000; /* Add downward-facing arrow */
    position: absolute;
    top: 50%; /* Center vertically */
    right: 10px;
    transform: translateY(-50%); /* Center arrow */
  }

  .dropdown-submenu:hover::after {
    border-top: 5px solid #fff; /* Change arrow color on hover */
  }

  .dropdown-item a,
  .dropdown-menu .dropdown-item a {
    display: block;
    width: 100%;
  }
}

/* ---------------------------------Old CSS--------------------------------------------- */
/* #menu {
  cursor: pointer;
  display: none;
}

.top-bar,
.btm-bar {
  position: absolute;
  width: 21px;
  height: 2px;
  background: white;
  left: 9px;
  top: 11px;
  border-radius: 10px;
  transition: all 500ms cubic-bezier(1, -1, 0.5, 2);
}

.btm-bar {
  top: 27px;
}

.mid-bar {
  stroke-linecap: round;
  stroke: #fff;
  stroke-dasharray: 0 100 40 400;
  stroke-dashoffset: 101;
  transition: all 750ms ease;
}

.navbarSm {
  position: absolute;
  top: 0;
  left: 0px;
  z-index: 5;
  padding: 10px;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
}

.navbarSm:hover {
  background-color: #00000060;
}

nav.navbarSm .navList {
  column-gap: 25px;
}

nav.navbarSm a {
  text-decoration: none;
  color: #fff;
  font-weight: 400;
  font-size: calc(1.05 * var(--default));
}

nav.navbarSm ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navItem,
.menuItem {
  position: relative;
}

a.navLink {
  padding: 10px;
}

a.navLink.dropdownLink {
  position: relative;
}

a.navLink.dropdownLink::after {
  content: "";
  height: 0;
  width: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateX(-50%);
  padding-bottom: 10px;
}

a.submenuLink::after {
  content: "";
  height: 0;
  width: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #000;
  position: absolute;
  top: calc(50% - 5px);
  left: calc(100% - 10px);
  transform: translateX(-50%);
}

.dropdownMenu > .menuItem > a:hover::after {
  border-left: 5px solid #fff;
}

.dropdownMenu {
  display: none;
  position: absolute;
  top: 28px;
  left: 10px;
  background-color: #fff;
  width: 230px;
}

.dropdownMenu::before {
  content: "";
  height: 0;
  width: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  position: absolute;
  top: -5px;
  left: 20px;
  transform: translateX(-50%);
}

.dropdownMenu.showDropdown {
  display: block !important;
}

.dropdownMenu > .menuItem > a,
.dropdownSubMenu > .subMenuItem > a {
  padding: 3px;
}

.dropdownMenu > .menuItem > a.focused,
.dropdownSubMenu > .subMenuItem > a.focused {
  background-color: #48675d;
  transition: background-color 0.2s ease-in-out;
  color: #fff;
  -webkit-transition: background-color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
}

.dropdownMenu > .menuItem > a.focused::after {
  border-left: 5px solid #fff;
}

.dropdownMenu > .menuItem > a:hover,
.dropdownSubMenu > .subMenuItem > a:hover {
  background-color: #48675d;
  color: #fff;
  transition: background-color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
}

.dropdownMenu > .menuItem a {
  display: block;
  width: 100%;
  color: #000;
  padding: 4px 5px;
  font-weight: 500;
}

.dropdownSubMenu {
  display: none;
  position: absolute;
  top: 3px;
  left: 230px;
  background-color: #fff;
  width: 200px;
}

.dropdownSubMenu.showDropdown {
  display: block !important;
}

@media only screen and (max-width: 991.98px) {
  #menu.open .top-bar {
    transform-origin: 22px;
    transform: translateX(-2px) rotate(-45deg);
    transition: all 0.75s cubic-bezier(1, -1.5, 0.5, 2);
  }

  #menu.open .btm-bar {
    transform-origin: 22px;
    transform: translateX(-2px) rotate(45deg);
    transition: all 0.75s cubic-bezier(1, -1.5, 0.5, 2);
  }

  #menu.open .mid-bar {
    stroke-dasharray: 0 120 251 251;
    stroke-dashoffset: 1;
  }

  nav.navbarSm {
    background-color: #48675de1;
    padding-bottom: 10px;
  }

  nav.navbarSm .navList {
    height: 0;
    transition: height 0.3s ease;
    overflow: hidden;
    row-gap: 20px;
  }

  nav.navbarSm .navList > li:first-child {
    margin-top: 30px;
  }

  nav.navbarSm .navList.navOpen {
    height: 95vh;
    transition: height 0.3s ease;
    overflow: hidden;
  }

  nav.navbarSm .navList li {
    width: 100%;
    text-align: center;
  }

  nav.navbarSm .navList li {
    width: 100%;
  }

  a.navLink {
    padding: 0px 10px;
  }

  .navItem {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  a.submenuLink::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
  }

  .dropdownMenu > .menuItem > a:hover::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
    top: 10px;
  }

  .dropdownMenu::before {
    border: none;
  }

  .dropdownMenu {
    width: 90%;
    position: static;
    background-color: #fff;
  }

  .dropdownSubMenu {
    width: 100%;
    position: static;
    background-color: #8dada3;
  }

  .dropdownSubMenu::before {
    border: none !important;
  }
} */
